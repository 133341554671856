import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "../../style/admin.css";
import Header from "../../Components/Header/Header"
import Modal from "./components/Modal/Modal";

const Arlista = () => {
  const [arlistak, setArlistak] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchArlistak = async () => {
      const res = await axios.get("/api/arkategoriak");
      setArlistak(res.data);
    };
    fetchArlistak();
  }, []);

  const handleModal = () => {
    setModalOpen(true);
   
  }

  return (
    <>
      <Header />
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      <Row>
        <Col xs={4}>
        </Col>
        <Col xs={4}>
          <div className="block aboutBlock">
            <Container>
              {arlistak.map((a) => (
                <LinkContainer to={`/admin/arlista/${a._id}`} className="links__admin-all">
                  <Row>
                    {" "}
                    <span className="list-span">{a.name}</span>
                  </Row>
                </LinkContainer>
              ))}
              <div style={{ marginTop: "20px" }}>
               

                <span className="newButton" onClick={handleModal}>Új lista létrehozása</span>


              </div>
            </Container>
          </div>
        </Col>
        <Col xs={4}>
        </Col>
      </Row>
    </>
  );
};

export default Arlista;
