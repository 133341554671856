import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import "./style.css";
import axios from "axios";

const Settings = () => {
  const [id, setId] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");

  useEffect(() => {
    const fetchSettings = async () => {
      const res = await axios.get("/api/settings/6227993c1a572abca41a1abd");
      setId(res.data._id);
      setMetaTitle(res.data.metaTitle);
      setMetaDesc(res.data.metaDesc);

      // setGooglemapslink(res.data.googlemapslink);
    };
    fetchSettings();
  }, []);

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/settings/${id}`, {
        metaTitle,
        metaDesc,
      });
    } catch (err) {}
  };

  const handleCancel = () => {
    window.location.replace("/admin/");
  };
  return (
    <>
      <Header />
      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          <div className="block aboutBlock">
            <div>
              <div>
                <legend>Alapbeállítások</legend>
              </div>
              <div>
                <h2 className="admin-h2">Weboldal címe</h2>
                <input
                  type="text"
                  value={metaTitle}
                  className="contInputStyle"
                  autoFocus
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </div>
              <div>
                <h2 className="admin-h2">
                  Alapértelmezett SEO leírás (max. 160 karakter)
                </h2>
                <textarea
                  type="text"
                  value={metaDesc}
                  className="localDescInput"
                  autoFocus
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button className="cancelButton" onClick={handleCancel}>
                Mégsem
              </button>
              <button className="createButton" onClick={handleUpdate}>
                Mentés
              </button>
            </div>
          </div>
        </Col>
        <Col xs={4}></Col>
      </Row>
    </>
  );
};

export default Settings;
