import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import "../../style/admin.css";

import Header from "../../Components/Header/Header";

const Kezelesek = () => {
  const [treatments, setTreatments] = useState([]);

  useEffect(() => {
    const fetchTreatments = async () => {
      const res = await axios.get("/api/kezelesek");
      setTreatments(res.data);
     
    };
    fetchTreatments();
  }, []);
  return (
    <>
      <Header />

      <Row>
        <Col sm={4}></Col>
        <Col sm={4}>
          <div className="block aboutBlock createNew-div">
            <Row>
              <Container>
                <Row>
                  {treatments.map((t) => (
                    <LinkContainer to={`/admin/kezelesek/${t.slug}`} className="links__admin-all">
                      <Row>
                        {" "}
                        <span className="list-span">{t.title}</span>
                      </Row>
                    </LinkContainer>
                  ))}
                </Row>
                <Row style={{marginTop:"20px"}}>
                  <LinkContainer to={`/admin/kezelesek/create`}>
                    <span className="newButton">Új kezelés létrehozása</span>
                  </LinkContainer>
                </Row>
              </Container>
            </Row>
          </div>
        </Col>
        <Col sm={4}></Col>
      </Row>
    </>
  );
};

export default Kezelesek;
