import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useContext } from "react";
import { Context } from "./context/Context";
import CookieConsent from "react-cookie-consent";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import ContactScreen from "./screens/ContactScreen/ContactScreen";
import KezelesekScreen from "./screens/KezelesekScreen/KezelesekScreen";
import ArakScreen from "./screens/ArakScreen/ArakScreen";
import SingleTreat from "./screens/KezelesekScreen/components/SingleTreat/SingleTreat";
import AboutUsScreen from "./screens/AboutUsScreen/AboutUsScreen";
import AszfScreen from "./components/AszfScreen/AszfScreen";
import Dashboard from "./admin/Screens/Dashboard/Dashboard";
import KezelesekAdmin from "./admin/Screens/Kezelesek/Kezelesek";
import ArlistakAdmin from "./admin/Screens/Arlistak/Arlistak";
import SettingsAdmin from "./admin/Screens/Settings/Settings";
import KezelesAdmin from "./admin/Screens/Kezeles/Kezeles";
import ArlistaAdmin from "./admin/Screens/Arlista/Arlista";
import ArEditAdmin from "./admin/Screens/ArEdit/ArEdit";
import ArCreateAdmin from "./admin/Screens/ArCreate/ArCreate";
import KezelesCreateAdmin from "./admin/Screens/KezelesCreate/KezelesCreate";
import CategoryCreateAdmin from "./admin/Screens/CategoryCreate/CategoryCreate";
import LoginScreen from "./admin/Screens/LoginScreen/LoginScreen";

import EgeszsegpScreen from "./admin/Screens/Settings/screens/EgeszsegpScreen/EgeszsegpScreen";
import KapcsolatScreen from "./admin/Screens/Settings/screens/KapcsolatScreen/KapcsolatScreen"

import EpCreate from "./admin/Screens/Settings/screens/EgeszsegpScreen/Components/EpCreate";

function App() {
  const { user } = useContext(Context);
  return (
    <Router>
      <Switch>
        <Route path="/admin/arlista/edit/:id">
          {user ? <ArEditAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/arlista/:id/create">
          {user ? <ArCreateAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/arlista/new-price-category">
          {user ? <CategoryCreateAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/arlista/:id">
          {user ? <ArlistaAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/arlista">
          {user ? <ArlistakAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/kezelesek/create">
          {user ? <KezelesCreateAdmin /> : <LoginScreen />}
        </Route>
        {/* <Route path={"/admin/kezelesek/:id"}>
          {user ? <KezelesAdmin /> : <LoginScreen />}
        </Route> */}

        <Route path={"/admin/kezelesek/:slug"}>
          {user ? <KezelesAdmin /> : <LoginScreen />}
        </Route>

        <Route path="/admin/kezelesek">
          {user ? <KezelesekAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/settings">
          {user ? <SettingsAdmin /> : <LoginScreen />}
        </Route>
        <Route path="/admin/egeszsegpenztarak/new">
          {user ? <EpCreate /> : <LoginScreen />}
        </Route>
        <Route path="/admin/egeszsegpenztarak">
          {user ? <EgeszsegpScreen /> : <LoginScreen />}
        </Route>
        <Route path="/admin/kapcsolat-beallitasok">
          {user ? <KapcsolatScreen /> : <LoginScreen />}
        </Route>
        <Route path="/admin">{user ? <Dashboard /> : <LoginScreen />}</Route>

        <div>
          <CookieConsent
            location="bottom"
            buttonText="Rendben"
            cookieName="<cookieName>"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            Az oldalon cookie-kat használunk, hogy a legjobb felhasználói
            élményt nyújthassuk.
            <span style={{ color: "blue" }}>
              <a href="/adatvedelmi-tajekoztato">További információk</a>
            </span>
          </CookieConsent>
          <Header />

          <Container
            style={{ paddingLeft: "0", paddingRight: "0", minHeight: "80vh" }}
            fluid
          >
            <Route path="/" exact component={HomeScreen} />
            <Route path="/kapcsolat" component={ContactScreen} />
            <Route path="/adatvedelmi-tajekoztato" component={AszfScreen} />
            <Route path="/rolunk" component={AboutUsScreen} />
            <Route path="/arak" component={ArakScreen} />
            <Route path="/fogaszati-kezelesek/:slug" component={SingleTreat} />
            <Route
              path="/fogaszati-kezelesek"
              exact
              component={KezelesekScreen}
            />
          </Container>
          <Footer />
        </div>
      </Switch>
    </Router>
  );
}

export default App;
