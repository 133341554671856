import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../../Components/Header/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "./Components/Modal/Modal";

const EgeszsegpScreen = () => {

    const [epList, setEpList] = useState([]);


    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [isActive, setIsActive] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });


    const onEdit = ({ id, currentName, currentImage, currentIsActive }) => {
        setInEditMode({
            status: true,
            rowKey: id
        })
        setName(currentName);
        setImage(currentImage);
        setIsActive(currentIsActive)

    }


    const getEps = async () => {
        const res = await axios.get("/api/ep");
        setEpList(res.data);
    };



    useEffect(() => {
        const getEps = async () => {
            const res = await axios.get("/api/ep");
            setEpList(res.data);
        };
        getEps();
        console.log(epList)


    }, []);

    const handleUpdate = async ({ id }) => {
        try {
            await axios.put(`/api/ep/${id}`, {
                name,
                image,
                isActive,

            });
        } catch (err) { }
    };

    const handleServiceDelete = async ({ id }) => {
        if (window.confirm("Biztosan szeretnéd törölni ezt az egészségpénztárat?")) {
            try {
                await axios.delete(`/api/ep/${id}`);
            } catch (err) { }
        }
        getEps();
    };

    const onSave = ({ id, currentName, currentImage, currentIsActive }) => {
        handleUpdate({ id, currentName, currentImage, currentIsActive });
        setInEditMode({
            status: false,
            rowKey: null
        })
        getEps();
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value
        setIsActive(null);
        setImage(null);
        setName(null)
    }

//upload and update image

const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const data = new FormData();
    const filename = Date.now() + file.name;
    data.append("name", filename);
    data.append("file", file);
  

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios.post('/api/upload', data, config)
console.log("siker")
     setImage(filename)
     getEps();
  
    } catch (error) {
      console.error(error)
 
    }
  }

//upload and updazr image end



    const handleOnChange = () => {
        setIsActive(!isActive);

    };

    const handleModal=()=>{
        setModalOpen(true);
      }

    return (
        <>
            <Header />
            {modalOpen && <Modal setOpenModal={setModalOpen} />}
            <Row>
                <Col xs={2}>
                </Col>
                <Col xs={8}>
                    <div className="block aboutBlock">
                        <Container>
                            <legend>Egészségpénztárak</legend>
                            
                            <span onClick={handleModal} style={{color:"#0a82b5", cursor:"pointer"}}><i class="fa fa-plus-square fa-2x"></i></span>
                        
                                                

                            <table className='styled-table'>
                                <thead>
                                    <tr>
                                        <th>Név</th>
                                        <th>Kép</th>
                                        <th>Aktív</th>
                                        <th></th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {epList.map((item) => {
                                    return    (<tr key={item._id}>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item._id ? (

                                                        <input value={name} style={{ width: "100%" }}
                                                            onChange={(event) => setName(event.target.value)}
                                                        />
                                                    ) : (
                                                        item.name
                                                    )
                                                }
                                            </td>

                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item._id ? (

                                                        <input 
                                                        type="file"
                                                            onChange={uploadFileHandler}
                                                        />
                                                    ) : (
                                                        <img src={`/media/images/${item.image}`} style={{maxWidth:"50px"}} />
                                                        
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item._id ? (

                                                        <input type={"checkbox"} checked={isActive}
                                                            onChange={handleOnChange}
                                                        />
                                                    ) : (
                                                        <input type={"checkbox"} checked={item.isActive} />
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item._id ? (
                                                        <React.Fragment>
                                                            <button
                                                                className={"btn-success"}
                                                                onClick={() => onSave({ id: item._id, currentImage: item.image, currentName: item.name, currentIsActive: item.isActive })}
                                                            >
                                                                Mentés
                                                            </button>

                                                            <button
                                                                className={"btn-secondary"}
                                                                style={{ marginLeft: 8 }}
                                                                onClick={() => onCancel()}
                                                            >
                                                                Mégsem
                                                            </button>
                                                        </React.Fragment>
                                                    ) : (
                                                        <button
                                                            className={"btn-primary"}
                                                            onClick={() => onEdit({ id: item._id, currentImage: item.image, currentName: item.name, currentIsActive: item.isActive })}
                                                        >
                                                            Szerkesztés
                                                        </button>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                <button
                                                    className={"btn-danger"}
                                                    onClick={() => handleServiceDelete({
                                                        id: item._id
                                                    })}
                                                >
                                                    Törlés
                                                </button>
                                            </td>

                                        </tr>)


                                    })}
                                </tbody>
                            </table>
                            <div></div>
                        </Container>

                    </div>
                </Col>
                <Col xs={2}>
                </Col>
            </Row>
        </>
    );
};

export default EgeszsegpScreen;
