import { useState } from "react";
import { useLocation } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header"
import axios from "axios";

const ArCreate = ({ history }) => {
  const location = useLocation();
  const path = location.pathname.split("/")[3];

  const [name, setName] = useState("");
  const [priceMin, setPriceMin] = useState();
  const [priceMax, setPriceMax] = useState();
  const [miniList, setMiniList] = useState(false);



  const handleCreate = async (e) => {
    e.preventDefault();
    const newPrice = {
      name,
      priceMin,
      priceMax,
      miniList,
      priceCategoryID: path,
    };
    try {
      const res = await axios.post("/api/arak", newPrice);
    } catch (err) {}
    window.history.back();
  };

  return (
    <>
          <Header />
      <Row>
        <Col xs={4}>
        </Col>
        <Col xs={4}>
          <div className="block aboutBlock">
            <Container>
              <legend>Új szolgáltatás létrehozása</legend>
              <div>
                <h2 className="admin-h2">Szolgáltatás neve</h2>
                <input
                  size="50"
                  type="text"
                  value={name}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <h2 className="admin-h2">Ár</h2>
                <input
                placeholder="Min. ár"
                  size="15"
                  type="text"
                  value={priceMin}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setPriceMin(e.target.value)}
                />
                <span> - </span>
                <input
                placeholder="Max. ár"
                  size="15"
                  type="text"
                  value={priceMax}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setPriceMax(e.target.value)}
                />
              </div>

              <div>
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={miniList}
                  onChange={(e) => setMiniList(e.target.checked)}
                />
                <label style={{ marginLeft: "10px" }} for="scales">
                  Megjelenítés a kezelés oldalán
                </label>
              </div>

              <div>
                <button
                  type="button"
                  className="btn-success"
                  style={{ float: "right" }}
                  onClick={handleCreate}
                >
                  Mentés
                </button>
              </div>
            </Container>
          </div>
        </Col>
        <Col xs={4}>
        </Col>
      </Row>
    </>
  );
};

export default ArCreate;
