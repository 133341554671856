import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header"
import axios from "axios";
import "./style.css"

import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const KezelesCreate = () => {
  const editorContent = EditorState.createEmpty();
  const [editorState, setEditorState] = useState({
    editorState: editorContent,
  });

  const [title, setTitle] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState(null);
  const [thumbnail, setThumbnail] = useState("");
  const [priceCategory, setPriceCategory] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [arlistak, setArlistak] = useState([]);

  useEffect(() => {
    const fetchArlistak = async () => {
      const res = await axios.get("/api/arkategoriak");
      setArlistak(res.data);
     
    };
    fetchArlistak();
    
  }, []);

  const handleEditorChange = (editorState) => {
    setEditorState({ editorState });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const newTreatment = {
      title,
      shortDesc,
      desc: JSON.stringify(
        convertToRaw(editorState.editorState.getCurrentContent())
      ),
      image,
      thumbnail,
      priceCategory,
      metaDesc,
    };
    if (image) {
      const data = new FormData();
      const filename = Date.now() + image.name;
      data.append("name", filename);
      data.append("file", image);
      newTreatment.image = filename;
      try {
        await axios.post("/api/upload", data);
      } catch (err) {}
    }

    if (thumbnail) {
      const data = new FormData();
      const filename = Date.now() + thumbnail.name;
      data.append("name", filename);
      data.append("file", thumbnail);
      newTreatment.thumbnail = filename;
      try {
        await axios.post("/api/upload", data);
      } catch (err) {}
    }

    try {
      const res = await axios.post("/api/kezelesek", newTreatment);
      window.location.replace("/admin/kezelesek/" + res.data.slug);
    } catch (err) {}
  };

  const handleCancel = async () => {
    window.location.replace("/admin/kezelesek/");
  };

  return (
    <>
            <Header />
      <Container>
        <Row>
          <Col xs={2}>
          </Col>
          <Col xs={8}>
            <div className="block aboutBlock">
              <div>
                <legend>Új kezelés létrehozása</legend>
              </div>
              <div>
                <h2 className="admin-h2">Kezelés címe</h2>
                <input
                  size="50"
                  type="text"
                  value={title}
                  className="TitleInput"
                  autoFocus
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div>
                <h2 className="admin-h2">Kezelés rövid leírása</h2>
                <textarea
                  className="ShortDescInput"
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                />
              </div>

              <div>
                <h2 className="admin-h2">Kezelés leírása</h2>
                <div>
                  <Editor
                    editorState={editorState.editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                  />
                </div>
              </div>

              <div>
                <h2 className="admin-h2">Kép</h2>
                <input
                  size="50"
                  type="file"
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>

              <div>
                <h2 className="admin-h2">Kis kép</h2>
                <input
                  size="50"
                  type="file"
                  className="ThumbnailInput"
                  autoFocus
                  onChange={(e) => setThumbnail(e.target.files[0])}
                />
              </div>

              <div>
                <h2 className="admin-h2">Árkategória</h2>
                <select id="arkategoriak" name="arkategoriak" className="pricecategory" onChange={(e) => setPriceCategory(e.target.value)} value={priceCategory}>
            {arlistak.map((item, i) =>
              <option key={i} value={item._id}>{item.name}</option>
            )}
          </select>
              </div>

              <div>
                <h2 className="admin-h2">SEO leírás</h2>
                <textarea
                  id="seoDesc"
                  maxLength="160"
                  rows="4"
                  cols="100"
                  className="MetaDescInput"
                  value={metaDesc}
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </div>
              <div>
                <button className="cancelButton" onClick={handleCancel}>
                  Mégsem
                </button>
                <button className="createButton" onClick={handleCreate}>
                  Létrehozás
                </button>
              </div>
            </div>
          </Col>
          <Col xs={2}>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KezelesCreate;
