import React, { useState } from 'react'
import Gallery from 'react-dynamic-image-gallery'
import image1 from '../../../images/aboutus/1.jpg'
import image2 from '../../../images/aboutus/2.jpg'
import image3 from '../../../images/aboutus/3.jpg'
import image4 from '../../../images/aboutus/4.jpg'
 
const IMAGES = [
    { id: 1, path: image1 },
    { id: 2, path: image2 },
    { id: 3, path: image3 },
    { id: 4, path: image4 },
]
 
function MyGallery() {
    const [source, setSource] = useState(IMAGES)
    
    

    return (
        <div >
          <Gallery
            source={source}
            itemsToShow={4}
            itemsToShowInPreview={4}
            
          />
        </div>
    )
}
 
export default MyGallery