import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Kezelesek from "./components/Kezelesek/Kezelesek";
import axios from "axios";
import Meta from "../../components/Meta/Meta";

const KezelesekScreen = () => {
  const [treatments, setTreatments] = useState([]);

  useEffect(() => {
    const fetchTreatments = async () => {
      const res = await axios.get("/api/kezelesek");
      setTreatments(res.data);
    };
    fetchTreatments();
  }, []);

  return (
    <>
      <Meta title="Kezelések | Oxydental" />
      <Container>
        <Kezelesek treatments={treatments} />
      </Container>
    </>
  );
};

export default KezelesekScreen;
