import React from "react";
import { Container } from "react-bootstrap";
import Meta from "../../components/Meta/Meta";
import Gallery from "./components/ImageGallery";

const AboutUsScreen = () => {
  return (
    <>
      <Meta title="Rólunk | Oxydental" />
      <div id="about" className="block aboutBlock">
        <div className="titleHolder">
          <h2>OXYDENTAL® Újpest – A tördődő fogászat</h2>
        </div>
        <div className="contentHolder">
          <Container>
            <p>
              Az a célunk, hogy az érdeklődők illetve akikből pácienseink
              lesznek ne passzív szereplők legyenek abban a folyamatban ami
              velük történik, hanem a lehetőségek határain belül kvázi
              “profiként” értsék meg mi zajlik körülöttük, velük.
            </p>
            <h2 style={{fontSize:"20px"}}>Munkatársaink</h2>
            <p>
              <strong>Dr. Vincze József</strong><br/>
              Dentoalveolaris sebész, fog-és szájbetegségek szakorvosa (fogszakorvos)
            </p>
            <p>
            1997-ben végeztem a budapesti Semmelweis Orvostudományi Egyetem Fogorvostudományi Karán. 2000-ben fogszakorvosi minősítést szereztem (fog és szájbetegségek szakorvosa). 2000-2004 között a Központi Sztomatológiai Intézetben dolgoztam a parodontológiai, majd a protetikai és végül a szájsebészeti osztályon. Rendszeresen részt vettem az éjszakai ügyeleti ellátásban is. 2004-ben a Semmelweis Egyetem Szájsebészeti Klinikájának fekvőosztályán dolgoztam, majd dentoalveolaris sebészetből (járóbeteg vagy ambuláns szájsebészet) szakvizsgát tettem. Több magánrendelőben a szájsebészeti, azon belül főleg implantológiai szaktanácsadásban illetve ellátásban vettem és veszek részt. Bécsben 2004-től 2015-ig szakmai vezetőként vettem részt egy magánpraxis betegellátásban. Az Oxydental Óbuda III. kerületi rendelőjében az általános fogorvosi és szájsebészeti – implantációs ellátásban veszek részt. A XIV. kerületben Zuglóban a KIWA Dentalban a szájsebészeti-implantációs kezeléseket koordinálom.
            </p>

            <p>
              <strong>Erős Edina</strong><br/>
              Általános ápoló, fogászati szakasszisztens, dentálhigiénikus
            </p>
            <p>
            Az egészségügyi szakközépiskolai végzettségemet 2000-ben szereztem Vácott, mint általános ápoló, ápoló asszisztens. Majd tanulmányaimat Budapesten folytattam, ahol 2002-ben lettem fogászati szakasszisztens és dentálhigiénikus.
            </p>
            <ul>
              <li>
              2001-2002  Vác magánrendelő/fogszabályozás Papós doktornővel
              </li>
              <li>
              2002-2012  Budapest magánrendelő
              </li>
            </ul>
            <p>
            Jelenleg az Oxydental Újpest rendelőjében dentálhigiénikusként dolgozom.
            </p>
            <div style={{ marginLeft: "30%", marginRight: "30%" }}>
              <Gallery />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AboutUsScreen;
