import { useState } from "react";
import "./modal.css"
import axios from "axios";

const Modal = ({ setOpenModal }) => {
    const [name, setName] = useState("");

    const handleCreate = async (e) => {
      e.preventDefault();
      const newCategory = {
        name,
      };
      try {
        const res = await axios.post("/api/arkategoriak", newCategory);
      } catch (err) { }
      setOpenModal(false)
      window.location.replace("/admin/arlista/")
    };
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        
        <div className="title">
          <h1>Új Árkategória létrehozása</h1>
        </div>
        <div className="body">
        <div >
                <input
                  type="text"
                  value={name}
                  placeholder="Árkategória neve"
                  className="TitleInput"
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Mégsem
          </button>
          <button 
           onClick={handleCreate}
        
          >Mentés</button>
        </div>
      </div>
    </div>
  )
}

export default Modal