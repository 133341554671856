import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./dashboard.css";
import Header from "../../Components/Header/Header";
import DashboardComponent from "./DashboardItem/Dashboard";
import Widgets from "./Widgets/Widgets";

export default function Dashboard() {
  return (
    <>
      <Header />
      <Row>
        <Col></Col>
        <Col xs={12} sm={6} md={12} lg={6}>
          <div className="block aboutBlock">
            {/* <div>
              <DashboardComponent />
            </div> */}
            <div>
              <Widgets />
            </div>
          </div>
        </Col>
        <Col></Col>
      </Row>
      <Row></Row>
    </>
  );
}
