import { useContext, useRef } from "react";
import { Context } from "../../../context/Context";
import { Link } from "react-router-dom";
import axios from "axios";
import "./login.css";
import logo from "./logo.png";

const LoginScreen = () => {
  const userRef = useRef();
  const passwordRef = useRef();
  const { dispatch, isFetching } = useContext(Context);

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/api/auth/login", {
        username: userRef.current.value,
        password: passwordRef.current.value,
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  return (
    <div className="login">
      <span className="loginTitle">Bejelentkezés</span>
      <form className="loginForm" onSubmit={handleLogin}>
        {/* <label>Username</label> */}
        <input
          type="text"
          className="loginInput"
          placeholder="Felhasználónév"
          ref={userRef}
        />
        {/* <label>Jelszó</label> */}
        <input
          type="password"
          className="loginInput"
          placeholder="Jelszó"
          ref={passwordRef}
        />
        <button className="loginButton" type="submit" disabled={isFetching}>
          Küldés
        </button>
      </form>
    </div>
  );
};

export default LoginScreen;
