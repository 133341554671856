import { useState } from "react";
import "./modal.css"
import axios from "axios";
const Modal = ({ setOpenModal }) => {
    const [name, setName] = useState("");
    const [image, setImage] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const handleCreate = async (e) => {
        e.preventDefault();
        const newEp = {
            name,
            image,
            isActive,
        };

        if (image) {
            const data = new FormData();
            const filename = Date.now() + image.name;
            data.append("name", filename);
            data.append("file", image);
            newEp.image = filename;
            try {
                await axios.post("/api/upload", data);
            } catch (err) { }
        }


        try {
            const res = await axios.post("/api/ep", newEp);
        } catch (err) { }
        window.location.replace("/admin/egeszsegpenztarak/")
    };
    return (
        <div className="modalBackground">
            <div className="modalContainer">

                <div className="title">
                    <h1>Új egészségpénztár létrehozása</h1>
                </div>
                <div className="body__addPrice">

                    <div>
                        
                        <input
                            size="50"
                            type="text"
                            value={name}
                            className="Ep__Input"
                            autoFocus
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div>
                        
                        <input
                            size="15"
                            type="file"
                            className="ImageInput"
                            autoFocus
                            onChange={(e) => setImage(e.target.files[0])}
                        />

                    </div>

                    <div>
                        <input
                            type="checkbox"
                            id="scales"
                            name="scales"
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                        />
                        <label style={{ marginLeft: "10px" }} for="scales">
                            Aktív
                        </label>
                    </div>
                    <div className="footer">
                        <button
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            id="cancelBtn"
                        >
                            Mégsem
                        </button>
                        <button
                            onClick={handleCreate}

                        >Mentés</button>
                    </div>
                </div>
            </div>
            </div>
            )
}

            export default Modal