import { useEffect, useState } from "react";
import axios from "axios";

const Arlista = ({ arkategoria }) => {
  const [arlistak, setArlistak] = useState([]);

  useEffect(() => {
    const fetchArlistak = async () => {
      const res = await axios.get("/api/arak");
      setArlistak(res.data);
    };
    fetchArlistak();
  }, []);

  return (
    <div>
      <h3 className="h3-arak">{arkategoria.name}</h3>

      <table>
        <tbody>
          {arlistak.map((item) => {
            if (item.priceCategoryID === arkategoria._id) {
              return (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td className="arakCol">
                    {" "}
                    {item.priceMax
                      ? // eslint-disable-next-line
                        item.priceMin + " Ft" + " - " + item.priceMax + " Ft"
                      : item.priceMin + " Ft"}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Arlista;
