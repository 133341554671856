import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer, XAxis, YAxis, CartesianGrid, Area, Tooltip, AreaChart
} from 'recharts';

export const ChartItem = (props) => {
  const { data, xKey, valKey } = props;
  return (
    <ResponsiveContainer height="75%" width="90%">
      <AreaChart  data={data}>
        <XAxis  />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Area type="monotone" dataKey={valKey} stroke="#69bcff" fill="#69bcff" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

ChartItem.propTypes = {
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string,
  valKey: PropTypes.string,
};

ChartItem.defaultProps = {
  xKey: 'end',
  valKey: 'value',
};

export default ChartItem;
