import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./feature.css";
import image1 from "./images/diagnosztika.jpg";
import image2 from "./images/prevencio.jpg";
import image3 from "./images/parodontologia.jpg";
import image4 from "./images/konzervalo.jpg";
import image5 from "./images/fogpotlas.jpg";
import image6 from "./images/szajsebeszet.jpg";
import { Card, Row, Col } from "react-bootstrap";

function Feature() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div id="feature" className="block featureBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Fogászati kezelések</h2>
        </div>
        <Row>
          <Col className="py-3" md={12} lg={6} xl={4}>
            <LinkContainer to="/fogaszati-kezelesek/fogorvosi-vizsgalatok-diagnosztika">
              <Card
                onClick={scrollToTop}
                className="feature-card"
                style={{ maxwidth: "18rem" }}
              >
                <Card.Img
                  variant="top"
                  src={image1}
                  alt="Fogorvosi vizsgálatok, diagnosztika"
                />
                <Card.Body>
                  <Card.Title className="cardTitle">
                    Fogorvosi vizsgálatok, diagnosztika
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>

          <Col className="py-3" md={12} lg={6} xl={4}>
            <LinkContainer to="/fogaszati-kezelesek/fogaszati-prevencio-megelozes">
              <Card
                onClick={scrollToTop}
                className="feature-card"
                style={{ maxwidth: "18rem" }}
              >
                <Card.Img
                  variant="top"
                  src={image2}
                  alt="Fogászati prevenció (megelőzés)"
                />
                <Card.Body>
                  <Card.Title className="cardTitle">
                    Fogászati prevenció (megelőzés)
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>

          <Col className="py-3" md={12} lg={6} xl={4}>
            <LinkContainer to="/fogaszati-kezelesek/parodontologia-fogagykezeles">
              <Card
                onClick={scrollToTop}
                className="feature-card"
                style={{ maxwidth: "18rem" }}
              >
                <Card.Img
                  variant="top"
                  src={image3}
                  alt="Parodontologia (fogágykezelés)"
                />
                <Card.Body>
                  <Card.Title className="cardTitle">
                    Parodontologia (fogágykezelés)
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>

          <Col className="py-3" md={12} lg={6} xl={4}>
            <LinkContainer to="/fogaszati-kezelesek/konzervalo-fogaszat-fogmegtarto-kezelesek">
              <Card
                onClick={scrollToTop}
                className="feature-card"
                style={{ maxwidth: "18rem" }}
              >
                <Card.Img
                  variant="top"
                  src={image4}
                  alt="Konzerváló fogászat (fogmegtartó kezelések)"
                />
                <Card.Body>
                  <Card.Title className="cardTitle">
                    Konzerváló fogászat (fogmegtartó kezelések)
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>

          <Col className="py-3" md={12} lg={6} xl={4}>
            <LinkContainer to="/fogaszati-kezelesek/fogpotlasok">
              <Card
                onClick={scrollToTop}
                className="feature-card"
                style={{ maxwidth: "18rem" }}
              >
                <Card.Img variant="top" src={image5} alt="Fogpótlások" />
                <Card.Body>
                  <Card.Title className="cardTitle">Fogpótlások</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>

          <Col className="py-3" md={12} lg={6} xl={4}>
            <LinkContainer to="/fogaszati-kezelesek/szajsebeszeti-beavatkozasoka">
              <Card
                onClick={scrollToTop}
                className="feature-card"
                style={{ maxwidth: "18rem" }}
              >
                <Card.Img
                  variant="top"
                  src={image6}
                  alt="Szájsebészeti beavatkozások"
                />
                <Card.Body>
                  <Card.Title className="cardTitle">
                    Szájsebészeti beavatkozások
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
        </Row>
        {/* 
        <Row>
          <Col className="py-3" md={6}>
            <Card style={{ maxwidth: "18rem" }}>
              <Card.Img variant="top" src={image4} />
              <Card.Body>
                <Card.Title>
                  Konzerváló fogászat (fogmegtartó kezelések)
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col className="py-3" md={6}>
            <Card style={{ maxwidth: "18rem" }}>
              <Card.Img variant="top" src={image5} />
              <Card.Body>
                <Card.Title>Fogpótlások</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col className="py-3" md={6}>
            <Card style={{ maxwidth: "18rem" }}>
              <Card.Img variant="top" src={image6} />
              <Card.Body>
                <Card.Title>Szájsebészeti beavatkozások</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default Feature;
