import React, { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./header.css";
import { Context } from "../../../context/Context";

const Header = () => {
  const { dispatch } = useContext(Context);
  const { user } = useContext(Context);
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <Navbar className="NavBar" expand="lg" collapseOnSelect>
      <Container>
        <LinkContainer to="/admin">
        <Nav.Item>
          <span className="NavLinkStyle">Üdvözöllek, {user.name}! </span>
        </Nav.Item>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="hambMenu">
          <Nav className="ms-auto">
            <LinkContainer to="/admin/kezelesek">
              <Nav.Link className="NavLinkStyle">
                <span className="NavLinkItem">Kezelések</span>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/admin/arlista">
              <Nav.Link className="NavLinkStyle">
                <span className="NavLinkItem">Árlista</span>
              </Nav.Link>
            </LinkContainer>

            <NavDropdown
              title="Beállítások"
              id="username"
              className="NavLinkStyle"
            >
              <LinkContainer to="/admin/settings">
                <NavDropdown.Item className="NavLinkStyle">
                  Alapbeállítások
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/kapcsolat-beallitasok">
                <NavDropdown.Item className="NavLinkStyle">
                  Kapcsolat
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/egeszsegpenztarak">
                <NavDropdown.Item className="NavLinkStyle">
                  Egészségpénztárak
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <Nav.Link className="NavLinkStyle" onClick={handleLogout}>
              Kijelentkezés
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
