import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import Header from "../../Components/Header/Header"
// import "../../style/admin.css";
import "./kezeles.css"

import MyEditor from "./component/editor";

const Kezeles = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const [treatment, setTreatment] = useState({});

  const handleDelete = async () => {
    if (window.confirm("Biztosan szeretnéd törölni ezt a kezelést?")) {
      try {
        await axios.delete(`/api/kezelesek/${treatment._id}`);
        window.location.replace("/admin/kezelesek/");
      } catch (err) {}
    }
  };

  const handleCancel = async () => {
    window.location.replace("/admin/kezelesek/");
  };

  return (
    <>
          <Header />
      <Row>
        <Col xs={2}>
        </Col>
        <Col xs={8}>
          <div className="block aboutBlock">
            <Container>
              <div>
                <MyEditor />
              </div>
            </Container>
          </div>
        </Col>
        <Col xs={2}>
        </Col>
      </Row>
    </>
  );
};

export default Kezeles;
