import { useState } from "react";
import { useLocation } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../../../Components/Header/Header"
import axios from "axios";

const EpCreate = () => {
 

    const [name, setName] = useState("");
    const [image, setImage] = useState(null);
    const [isActive, setIsActive] = useState(false);
    

  const handleCreate = async (e) => {
    e.preventDefault();
    const newEp = {
      name,
      image,
      isActive,
    };

    if (image) {
        const data = new FormData();
        const filename = Date.now() + image.name;
        data.append("name", filename);
        data.append("file", image);
        newEp.image = filename;
        try {
          await axios.post("/api/upload", data);
        } catch (err) {}
      }


    try {
      const res = await axios.post("/api/ep", newEp);
    } catch (err) {}
    window.location.replace("/admin/egeszsegpenztarak/")
  };

  return (
    <>
          <Header />
      <Row>
        <Col xs={4}>
        </Col>
        <Col xs={4}>
          <div className="block aboutBlock">
            <Container>
              <legend>Új egészségpénztár feltöltése</legend>
              <div>
                <h2 className="admin-h2">Egészségpénztár neve</h2>
                <input
                  size="50"
                  type="text"
                  value={name}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <h2 className="admin-h2">Kép</h2>
                <input
                  size="15"
                  type="file"
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setImage(e.target.files[0])}
                />
               
              </div>

              <div>
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
                <label style={{ marginLeft: "10px" }} for="scales">
                  Aktív
                </label>
              </div>

              <div>
                <button
                  type="button"
                  className="btn-success"
                  style={{ float: "right" }}
                  onClick={handleCreate}
                >
                  Mentés
                </button>
              </div>
            </Container>
          </div>
        </Col>
        <Col xs={4}>
        </Col>
      </Row>
    </>
  );
};

export default EpCreate;
