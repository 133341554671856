import React, { useEffect, useState } from "react";
import "./widgets.css";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";

const Widgets = () => {
  const [epList, setEpList] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [priceListItems, setPriceListItems] = useState([]);

  const getEps = async () => {
    const res = await axios.get("/api/ep/sort");
    setEpList(res.data);
  };

  const fetchTreatments = async () => {
    const res = await axios.get("/api/kezelesek/sort");
    setTreatments(res.data);
  };

  const getPrices = async () => {
    const res = await axios.get("/api/arak/sort");
    setPriceListItems(res.data);
  };

  useEffect(() => {
    getEps();
    fetchTreatments();
    getPrices();
  }, []);

  return (
    <>
      <div className="dashboard__title-holder">
        <h1 className="dashboard__h1-title">Utoljára szerkesztett elemek</h1>
      </div>
      <div className="dashboard__widget-wrap">
        <div className="dashboard__widget">
          <div className="dashboard__widget-title">Kezelések</div>
          <div className="dashboard__widget-body">
            {treatments.map((item) => {
              return (
                <Link
                  to={`/admin/kezelesek/${item.slug}`}
                  className="links__widget"
                >
                  <span key={item._id} className="dashboard__widget-body-span">
                    {item.title}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="dashboard__widget">
          <div className="dashboard__widget-title">Árak</div>
          <div className="dashboard__widget-body">
            {priceListItems.map((item) => {
              return (
                <Link
                  to={`/admin/arlista/${item.priceCategoryID}`}
                  className="links__widget"
                >
                  <span key={item._id} className="dashboard__widget-body-span">
                    {item.name}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="dashboard__widget">
          <div className="dashboard__widget-title">Egészségpénztárak</div>
          <div className="dashboard__widget-body">
            {epList.map((item) => {
              return (
                <Link to={`/admin/egeszsegpenztarak`} className="links__widget">
                  <span key={item._id} className="dashboard__widget-body-span">
                    {item.name}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Widgets;
