import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import axios from "axios";
import { withRouter } from "react-router";
import { useHistory, useParams } from "react-router-dom";

import "./style.css";

class MyEditor extends Component {
  state = {
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML("<p>Kezelés részletes bemutatása...</p>")
      )
    ),
    id: "",
    title: "",
    shortDesc: "",
    image: "",
    thumbnail: "",
    priceCategory: "",
    metaDesc: "",
    categItems: [],
    setUploading: false,
    updateThumbnail: "",
  };

  componentDidMount() {
    const pathname = window.location.pathname.split("/")[3];

    //TESZT KEZDEZE
    function getTreatment() {
      return axios.get(`/api/kezelesek/${pathname}`);
    }

    function getCategories() {
      return axios.get("/api/arkategoriak");
    }

    axios.all([getTreatment(), getCategories()]).then(
      axios.spread((res, categ) => {
        const treatment = res.data;
        this.setState({ title: res.data.title });
        this.setState({ id: res.data._id });
        this.setState({ shortDesc: res.data.shortDesc });
        this.setState({ image: res.data.image });
        this.setState({ thumbnail: res.data.thumbnail });
        this.setState({ metaDesc: res.data.metaDesc });
        this.setState({ priceCategory: res.data.priceCategory });

        if (treatment) {
          const blocksFromHTML = convertFromHTML(treatment.desc);
          const state = convertFromRaw(JSON.parse(treatment.desc));
          // const state = ContentState.createFromBlockArray(
          //     blocksFromHTML.contentBlocks,
          //     blocksFromHTML.entityMap,
          // );
          this.setState({ editorState: EditorState.createWithContent(state) });
        } else {
          this.setState({ editorState: EditorState.createEmpty() });
        }

        this.setState({ categItems: categ.data });
      })
    );
  }

  //title onChange
  titleState = (e) => {
    this.setState({ title: e.target.value });
  };

  shortDescState = (e) => {
    this.setState({ shortDesc: e.target.value });
  };

  // imageState = (e) => {
  //   this.setState({ image: e.target.value });
  // };

  priceCategoryState = (e) => {
    this.setState({ priceCategory: e.target.value });
  };

  thumbnailState = (e) => {
    this.setState({ thumbnail: e.target.value });
  };

  //Upload and update image begin

  uploadImageHandler = async (e) => {
    const file = e.target.files[0];

    const extension = e.target.files[0].type;
    // const formData = new FormData()
    const data = new FormData();
    const filename = Date.now() + file.name;
    data.append("name", filename);
    data.append("file", file);

    this.setState({ setUploading: true });

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.post("/api/upload", data, config);
      this.setState({ image: filename });

      this.setState({ setUploading: false });
    } catch (error) {
      this.setState({ setUploading: false });
    }
  };

  //Upload and update image end

  //Upload and update thumbnail begin

  uploadThumbnailHandler = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    const filename = Date.now() + file.name;
    data.append("name", filename);
    data.append("file", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.post("/api/upload", data, config);
      this.setState({ thumbnail: filename });
    } catch (error) {}
  };

  //Upload and update thumbnail end

  metaDescState = (e) => {
    this.setState({ metaDesc: e.target.value });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleUpdate = async () => {
    try {
      await axios.put(`/api/kezelesek/${this.state.id}`, {
        title: this.state.title,
        shortDesc: this.state.shortDesc,
        desc: JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        ),
        image: this.state.image,
        thumbnail: this.state.thumbnail,
        priceCategory: this.state.priceCategory,
        metaDesc: this.state.metaDesc,
      });

      window.location.replace("/admin/kezelesek/");
    } catch (err) {}
  };

  handleDelete = async () => {
    if (window.confirm("Biztosan szeretnéd törölni ezt a kezelést?")) {
      try {
        await axios.delete(`/api/kezelesek/${this.state.id}`);
        window.location.replace("/admin/kezelesek/");
      } catch (err) {}
    }
  };

  handleCancel = () => {
    window.location.replace("/admin/kezelesek/");
  };

  render() {
    const { editorState } = this.state;

    return this.state.editorState ? (
      <div>
        <div>
          <legend>Kezelés szerkesztése</legend>
        </div>

        <div>
          <h2 className="admin-h2">Kezelés címe</h2>
          <input
            size="50"
            type="text"
            value={this.state.title}
            className="TitleInput"
            autoFocus
            onChange={this.titleState}
          />
        </div>

        <div>
          <h2 className="admin-h2">Kezelés rövid leírása</h2>
          <textarea
            className="ShortDescInput"
            value={this.state.shortDesc}
            onChange={this.shortDescState}
          />
        </div>

        <div>
          <h2 className="admin-h2">Kezelés leírása</h2>
          <Editor
            editorState={editorState}
            wrapperClassName="kezelesek-wrapper"
            editorClassName="kezelesek-editor"
            onEditorStateChange={this.onEditorStateChange}
          />
          {/* <textarea
                        disabled
                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                    /> */}
        </div>

        <div>
          <h2 className="admin-h2">Kép</h2>
          <div>
            <img
              src={`/media/images/${this.state.image}`}
              style={{ width: "150px", marginRight: "20px" }}
              onChange={this.imageState}
            />

            <input
              name="image"
              size="25"
              type="file"
              className="ThumbnailInput"
              autoFocus
              onChange={this.uploadImageHandler}
            />
          </div>
        </div>

        <div>
          <h2 className="admin-h2">Kis kép</h2>
          <div>
            <img
              src={`/media/images/${this.state.thumbnail}`}
              style={{ width: "150px", marginRight: "20px" }}
            />
            <input
              size="25"
              type="file"
              className="ThumbnailInput"
              autoFocus
              onChange={this.uploadThumbnailHandler}
            />
          </div>
        </div>

        <div>
          <h2 className="admin-h2">Árkategória</h2>
          <select
            id="arkategoriak"
            name="arkategoriak"
            className="pricecategory"
            onChange={this.priceCategoryState}
            value={this.state.priceCategory}
          >
            {this.state.categItems.map((item, i) => (
              <option key={i} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <h2 className="admin-h2">SEO leírás</h2>
          <textarea
            rows="4"
            cols="100"
            className="MetaDescInput"
            maxLength="160"
            value={this.state.metaDesc}
            onChange={this.metaDescState}
          />
        </div>
        <div>
          <button className="deleteButton" onClick={this.handleDelete}>
            Kezelés törlése
          </button>
          <button className="cancelButton" onClick={this.handleCancel}>
            Vissza
          </button>
          <button className="updateButton" onClick={this.handleUpdate}>
            Frissítés
          </button>
        </div>
      </div>
    ) : (
      <p>loading</p>
    );
  }
}

export default MyEditor;
