import React from "react";
import { Row, Col } from "react-bootstrap";
import image1 from "./images/doctor.png";
import image2 from "./images/dentist-chair.png";
import image3 from "./images/best-price.png";
import "./icons.css";

function Icons() {
  return (
    <div id="about" className="block aboutBlock">
      <div
        className="container-fluid"
        style={{ paddingRight: "0", paddingLeft: "0" }}
      >
        <div className="titleHolder">
          <h2>Teljes körű fogorvosi és szájsebészeti ellátás Újpesten</h2>
        </div>
        <div className="contentHolder">
          <Row className="justify-content-md-center">
            <Col className="iconsCol" md={12} lg={6} xl={4}>
              <div className="icons-card">
                <div className="icons-div">
                  <img
                    className="img-icons"
                    src={image1}
                    alt="Kiváló fogszakorvos"
                  />
                </div>
                <div className="icons-title-div">
                  <h3 className="h3-icons">Kiváló szakmai csapat</h3>
                </div>
                <div>
                  <p style={{ textAlign: "center" }}>
                    Megtapasztalhatja az orvosok, asszisztensek, technikusok
                    megbízható és minőségi munkáján kívül a figyelmes,
                    emberközpontú betegellátást is.
                  </p>
                </div>
              </div>
            </Col>

            <Col className="iconsCol" md={12} lg={6} xl={4}>
              <div className="icons-card">
                <div className="icons-div">
                  <img
                    className="img-icons"
                    src={image2}
                    alt="Modern fogászati rendelő"
                  />
                </div>
                <div className="icons-title-div">
                  <h3 className="h3-icons">Modern technológia</h3>
                </div>
                <div>
                  <p style={{ textAlign: "center" }}>
                    Rendelőnk megfelel a fogorvosi és szájsebészeti ellátás
                    legkorszerűbb követelményeinek.
                  </p>
                </div>
              </div>
            </Col>

            <Col className="iconsCol" md={12} lg={6} xl={4}>
              <div className="icons-card">
                <div className="icons-div">
                  <img
                    className="img-icons"
                    src={image3}
                    alt="Megfizethető fogászat"
                  />
                </div>
                <div className="icons-title-div">
                  <h3 className="h3-icons">Megfizethető árak</h3>
                </div>
                <div>
                  <p style={{ textAlign: "center" }}>
                    A főváros egyik legjobb árfekvésű szolgáltatásait kínáljuk.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Icons;
