import React, { useEffect, useState } from "react";
import "./booking.css";
import axios from "axios";
import { Alert, Row, Col } from "react-bootstrap";

const Booking = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [message, setMessage] = useState("");

  // const [alert, setAlert] = useState(false);
  // const [alertType, setAlertType] = useState("");

  // const submitEmail = async (e) => {
  //   e.preventDefault();
  //   const newBooking = {
  //     name,
  //     phoneNumber,
  //     email,
  //     message,
  //   };
  //   try {
  //     // eslint-disable-next-line
  //     const res = await axios.post("/api/send", newBooking);
  //     setAlert(true);
  //     setAlertType("booking-successfull");
  //   } catch (err) {
  //     setAlert(true);
  //     setAlertType("booking-fail");
  //   }
  // };

  const [phone, setPhone] = useState("");
  const fetchSettings = async () => {
    const res = await axios.get("/api/settings/6227993c1a572abca41a1abd");
    setPhone(res.data.phone);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div id="booking" className="block">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Kérjen időpontot személyes konzultációra!</h2>
        </div>

        <div className="contact-box">
          <Row>
            <Col>
              <a className="footerLinks" href={"tel:" + phone}>
                <span className="CallUsButton">
                  <i
                    class="fas fa-phone-alt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Időpontfoglalás {phone}
                </span>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Booking;
