import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const Meta = ({ keywords }) => {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");

  const fetchSettings = async () => {
    const res = await axios.get("/api/settings/6227993c1a572abca41a1abd");
    setMetaTitle(res.data.metaTitle);
    setMetaDesc(res.data.metaDesc);
  };
  useEffect(() => {
    fetchSettings();
  }, []);
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc} />
      <meta name="keyword" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  keywords: "újpest, fogászat, szájsebészet",
};

export default Meta;
