import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";

const ArEdit = ({ history }) => {
  const location = useLocation();
  const path = location.pathname.split("/")[4];
  console.log(path);

  const [name, setName] = useState("");
  const [priceMin, setPriceMin] = useState();
  const [priceMax, setPriceMax] = useState();
  const [miniList, setMiniList] = useState(false);

  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get("/api/arak/" + path);

      setName(res.data.name);
      setPriceMin(res.data.priceMin);
      setPriceMax(res.data.priceMax);
      setMiniList(res.data.miniList);
    };
    getPost();
  }, [path]);

  const handleUpdate = async () => {
    try {
      await axios.put("/api/arak/" + path, {
        name,
        priceMin,
        priceMax,
        miniList,
      });
    } catch (err) {}
    window.history.back();
  };

  const handleDelete = async () => {
    try {
      await axios.delete("/api/arak/" + path);
    } catch (err) {}
    window.history.back();
  };

  return (
    <>
      <Row>
        <Col xs={4}>
         
        </Col>
        <Col xs={8}>
          <div className="block aboutBlock">
            <Container>
              <legend>{name} szerkesztése</legend>
              <div>
                <h2 className="admin-h2">Megnevezés</h2>
                <input
                  size="50"
                  type="text"
                  value={name}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <h2 className="admin-h2">Ár</h2>
                <input
                  size="15"
                  type="text"
                  value={priceMin}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setPriceMin(e.target.value)}
                />
                <span> </span>
                <input
                  size="15"
                  type="text"
                  value={priceMax}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setPriceMax(e.target.value)}
                />
              </div>

              <div>
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={miniList}
                  onChange={(e) => setMiniList(e.target.checked)}
                />
                <label style={{ marginLeft: "10px" }} for="scales">
                  Megjelenítés a kezelés oldalán
                </label>
              </div>

              <div>
                <button
                  type="button"
                  style={{ float: "right" }}
                  onClick={handleUpdate}
                >
                  Mentés
                </button>
              </div>
              <div>
                <button
                  type="button"
                  style={{ float: "right" }}
                  onClick={handleDelete}
                >
                  Törlés
                </button>
              </div>

              {/* <div>
                <textarea
                  rows="12"
                  cols="100"
                  className="DescInput"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>

              <div>
                <input
                  size="50"
                  type="text"
                  value={image}
                  className="ImageInput"
                  autoFocus
                  onChange={(e) => setImage(e.target.value)}
                />
              </div> */}
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ArEdit;
