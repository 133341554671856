import React from "react";
import Arlista from "../Arlista/Arlista";

const Arlistak = ({ arkategoriak }) => {
  return (
    <div>
      {arkategoriak.map((a) => (
        <Arlista arkategoria={a} key={a._id} />
      ))}
    </div>
  );
};

export default Arlistak;
