import React from "react";
import "./about.css";

function About() {
  return (
    <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Amit vallunk és hiszünk a fogászatról</h2>
        </div>
        <div className="contentHolder">
          <p>
            Az a célunk, hogy az érdeklődők illetve akikből pácienseink lesznek
            ne passzív szereplők legyenek abban a folyamatban ami velük
            történik, hanem a lehetőségek határain belül kvázi “profiként”
            értsék meg mi zajlik körülöttük, velük.
          </p>
          <p>
            Ezért nagy a jelentősége a fogászatban, a szájsebészetben, beleértve
            az implantációt is, az első konzultációknak, állapotfelméréseknek,
            mivel ha valaki megérti a probléma gyökerét velünk együtt közösen
            tudja kialakítani a végső döntést!
          </p>
          <p>
            A döntést ugyanis Önnek kell meghozni, mi ebben minden segítséget
            megadunk, hogy tisztán érthető legyen, hogy valaki miért azt kapja
            amit, vagy, hogy valamikor akár az olcsóbb megoldás a jobb, sőt
            szebb, mik az egyes megoldások előnyei és hátrányai, mit és miért
            mérlegeltünk úgy ahogy. Ezek nélkül egyoldalúvá válik az orvos-beteg
            kapcsolat illetve a kezelés, és nem érhető el a vágyott siker, vagy
            ha igen akkor sem a közös munka eredményeként!
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
