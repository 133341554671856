import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header"
import axios from "axios";
import "./style.css"

const CategoryCreate = ({ history }) => {
  const [name, setName] = useState("");

  const handleCreate = async (e) => {
    e.preventDefault();
    const newCategory = {
      name,
    };
    try {
      const res = await axios.post("/api/arkategoriak", newCategory);
    } catch (err) { }
    window.history.back();
  };

  const handleCancel = async () => {
    window.history.back();
  };

  return (
    <>
      <Header />
      <Row>
        <Col xs={4}>
        </Col>
        <Col xs={4}>
          <div className="block aboutBlock">
            <legend>Új árkategória létrehozása</legend>
            <Container>
              <div >
                <input
                  type="text"
                  value={name}
                  className="TitleInput"
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ float: "left" }}>
                <div style={{ marginBottom: "15px", }}>
                  <button className="btn-success" onClick={handleCreate}>
                    Létrehozás
                  </button>
                </div>
                <div>
                  <button className="singlePostButton" style={{}} onClick={handleCancel}>
                    Vissza
                  </button>
                </div>
              </div>
            </Container>
          </div>
        </Col>
        <Col xs={4}>
        </Col>
      </Row>
    </>
  );
};

export default CategoryCreate;
