import { useState } from "react";
import "./modal.css"
import axios from "axios";
import { useLocation } from "react-router";
const Modal = ({ setOpenModal }) => {

    const location = useLocation();
    const path = location.pathname.split("/")[3];

    
    const [name, setName] = useState("");
    const [priceMin, setPriceMin] = useState();
    const [priceMax, setPriceMax] = useState();
    const [miniList, setMiniList] = useState(false);

    const handleCreate = async (e) => {
        e.preventDefault();
        const newPrice = {
          name,
          priceMin,
          priceMax,
          miniList,
          priceCategoryID: path,
        };
        try {
          const res = await axios.post("/api/arak", newPrice);
        } catch (err) {}
        window.location.replace("/admin/arlista/"+path)
        
      };
    return (
        <div className="modalBackground">
            <div className="modalContainer">

                <div className="title">
                    <h1>Új szolgáltatás létrehozása</h1>
                </div>
                <div className="body__addPrice">
                    
                    <div>
                        <input
                            
                            type="text"
                            value={name}
                            className="addPriceInput"
                            autoFocus
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            placeholder="Min. ár"
                        size={10}
                            type="text"
                            value={priceMin}
                            className="addPriceInput"
                            autoFocus
                            onChange={(e) => setPriceMin(e.target.value)}
                        />
                        <span> - </span>
                        <input
                            placeholder="Max. ár"
                           size={10}
                            type="text"
                            value={priceMax}
                            className="addPriceInput"
                            autoFocus
                            onChange={(e) => setPriceMax(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            type="checkbox"
                            id="scales"
                            name="scales"
                            checked={miniList}
                            onChange={(e) => setMiniList(e.target.checked)}
                        />
                        <label style={{ marginLeft: "10px" }} for="scales">
                            Megjelenítés a kezelés oldalán
                        </label>
                    </div>
                </div>
                <div className="footer">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                        id="cancelBtn"
                    >
                        Mégsem
                    </button>
                    <button
                        onClick={handleCreate}

                    >Mentés</button>
                </div>
            </div>
        </div>
    )
}

export default Modal