import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./footer.css";
import logo from "./logo.png";
import axios from "axios";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [epList, setEpList] = useState([]);
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const getEps = async () => {
    const res = await axios.get("/api/ep");
    setEpList(res.data);
  };

  const fetchSettings = async () => {
    const res = await axios.get("/api/settings/6227993c1a572abca41a1abd");
    setAddress(res.data.address);
    setEmail(res.data.email);
    setPhone(res.data.phone);

    // setGooglemapslink(res.data.googlemapslink);
  };

  useEffect(() => {
    getEps();
    fetchSettings();
  }, []);

  return (
    <footer className="box" style={{ backgroundColor: "#f9f9f9" }}>
      <Container fluid>
        <Row className="py-3">
          <Col sm>
            <img src={logo} alt="logo" />
            <p>Teljes körű fogorvosi és szájsebészeti ellátás Újpesten.</p>
            <div className="company-infos">
              <hr className="solid-divider"></hr>

              <div class="wrapper">
                {epList.map((item) => {
                  if (item.isActive === true) {
                    return (
                      <img
                        src={`/media/images/${item.image}`}
                        alt={item.name}
                        style={{
                          maxWidth: "80px",
                          marginBottom: "2px",
                          marginLeft: "2px",
                        }}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </Col>
          <Col sm>
            <p className="footerTitle" style={{ textAlign: "center" }}>
              Fogászati kezelések
            </p>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/fogorvosi-vizsgalatok-diagnosztika">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Fogorvosi diagnosztika
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/fogaszati-prevencio-megelozes">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Fogászati prevenció
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/parodontologia-fogagykezeles">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Parodontologia
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/konzervalo-fogaszat-fogmegtarto-kezelesek">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Fogmegtartó kezelések
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/fogpotlasok">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Fogpótlások
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/szajsebeszeti-beavatkozasok">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Szájsebészeti beavatkozások
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/fogekszerek">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Fogékszerek
                </span>
              </LinkContainer>
            </Row>

            <Row>
              <LinkContainer to="/fogaszati-kezelesek/otthoni-professzionalis-fogfeherites">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Otthoni professzionális fogfehérítés
                </span>
              </LinkContainer>
            </Row>
          </Col>
          <Col sm>
            <p className="footerTitle" style={{ textAlign: "center" }}>
              Kapcsolat
            </p>
            <ul>
              <li className="listItemFooter">
                <a
                  className="footerLinks"
                  href="https://www.google.com/maps/place/Oxydental+%C3%9Ajpest/@47.598414,19.1161263,17z/data=!3m1!4b1!4m5!3m4!1s0x4741d0a2bf14b933:0x5c661e6a11d7b709!8m2!3d47.598414!4d19.118315"
                >
                  {" "}
                  <i className="fas fa-map-marker-alt icons"></i>
                  {address}
                </a>
              </li>
              <li className="listItemFooter">
                <a className="footerLinks" href={"tel:" + phone}>
                  {" "}
                  <i className="fas fa-phone icons"></i>
                  {phone}
                </a>
              </li>
              <li className="listItemFooter">
                <a className="footerLinks" href={"mailto:" + email}>
                  {" "}
                  <i className="fas fa-envelope icons"></i>
                  {email}
                </a>
              </li>
            </ul>
            <div className="company-infos">
              <hr className="solid-divider"></hr>
              <LinkContainer to="/adatvedelmi-tajekoztato">
                <span className="footerItemKezelesek" onClick={scrollToTop}>
                  Adatvédelmi tájékoztató
                </span>
              </LinkContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
