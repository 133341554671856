import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./arak.css";
import axios from "axios";
import Arlistak from "./Components/Arlistak/Arlistak";
import Meta from "../../components/Meta/Meta";

const ArakScreen = () => {
  const [arkategoriak, setArkategoriak] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const res = await axios.get("/api/arkategoriak/");
      setArkategoriak(res.data);
    };
    getCategories();
  }, []);

  return (
    <>
      <Meta title="Áraink | Oxydental" />
      <div id="about" className="block aboutBlock">
        <div className="titleHolder">
          <h2>Fogászati kezelések árai</h2>
        </div>
        <div className="contentHolder">
          <Container className="arakContainer">
            <Arlistak arkategoriak={arkategoriak} />
          </Container>
        </div>
      </div>
    </>
  );
};

export default ArakScreen;
