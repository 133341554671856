import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./contactscreen.css";
import Meta from "../../components/Meta/Meta";
import axios from "axios";

const ContactScreen = () => {
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [opening, setOpening] = useState("");
  const [approach, setApproach] = useState("");
  // const [googlemapslink, setGooglemapslink] = useState("");

  useEffect(() => {
    const fetchSettings = async () => {
      const res = await axios.get("/api/settings/6227993c1a572abca41a1abd");
      setId(res.data._id);
      setAddress(res.data.address);
      setEmail(res.data.email);
      setPhone(res.data.phone);
      setOpening(res.data.opening);
      setApproach(res.data.approach);
      // setGooglemapslink(res.data.googlemapslink);
    };
    fetchSettings();
  }, []);
  return (
    <>
      <Meta title="Kapcsolat | Oxydental" />
      <div id="about" className="block aboutBlock">
        <div className="titleHolder">
          <h2>Kapcsolat</h2>
        </div>
        <div className="contentHolder">
          <Container>
            <Row>
              <Col md={6}>
                <Row>
                  <Col>
                    <h2>Elérhetőségeink</h2>
                    <ul>
                      <li className="listItem noDecoration">
                        <a
                          className="footerLinks"
                          href="https://www.google.com/maps/place/Oxydental+%C3%9Ajpest/@47.598414,19.1161263,17z/data=!3m1!4b1!4m5!3m4!1s0x4741d0a2bf14b933:0x5c661e6a11d7b709!8m2!3d47.598414!4d19.118315"
                        >
                          {" "}
                          <i className="fas fa-map-marker-alt icons"></i>
                          Cím: {address}
                        </a>
                      </li>
                      <li className="listItem noDecoration">
                        <a className="footerLinks" href="tel:+36303791537">
                          {" "}
                          <i className="fas fa-phone icons"></i>
                          Telefonszám: {phone}
                        </a>
                      </li>
                      <li className="listItem noDecoration">
                        <a
                          className="footerLinks"
                          href="mailto:oxydental.ujpest@gmail.com"
                        >
                          {" "}
                          <i className="fas fa-envelope icons"></i>
                          Email: {email}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>Nyitvatartás</h2>

                    <ul>
                      <li className="listItem noDecoration">
                        <i className="fas fa-clock icons"></i>
                        Hétfő–péntek: {opening}
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>Megközelítés</h2>
                    {/* <p>{approach}</p> */}
                    <p>
                      Tömegközlekedéssel illetve személygépkocsival is könnyen
                      megközelíthető. A közvetlen környéken bőséges az ingyenes
                      parkolóhely-kínálat. Tömegközlekedést igénybe véve
                      megközelíthetők vagyunk a következő járatokkal: buszok:
                      122, 30, 230, 296, 126; villamos: 14.
                    </p>
                    <p>
                      Újpesti rendelőnk könnyen megközelíthető Dunakesziről,
                      Fótról, a XV. kerületből is.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="py-3">
                <iframe
                  title="googlemap"
                  width="100%"
                  height="400px"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(Oxydental%20Hungary)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="http://www.gps.ie/">vehicle gps</a>
                </iframe>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ContactScreen;
