import React from "react";
import "./kezelesek.css";
import Kezeles from "../Kezeles/Kezeles";

const Kezelesek = ({ treatments }) => {
  return (
    <div id="feature" className="block featureBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Fogászati kezelések</h2>
        </div>
        <p className="contentHolder">
          Hogy egy kézben legyen minden még ha különböző fogászati,
          szájsebészeti problémái is vannak. Mindezt egy helyen! Konzultáció,
          kontroll, szaktanácsadás, teljes körű fogorvosi, szájsebészeti
          ellátás, implantáció Újpesten.
        </p>

        <div className="cardss">
          <div className="cardd">
            {treatments.map((t) => (
              <Kezeles treatment={t} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kezelesek;
