import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import "./tableStyle.css"
import Header from "../../Components/Header/Header"
import axios from "axios";
import Modal from "../ArCreate/components/Modal";

const Arlista = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const [priceList, setPriceList] = useState([]);
  const [priceListItems, setPriceListItems] = useState([]);

  const [name, setName] = useState("");
  const [priceMin, setPriceMin] = useState();
  const [priceMax, setPriceMax] = useState();
  const [miniList, setMiniList] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null
  });


  const onEdit = ({ id, currentMinPrice, currentMaxPrice, currentName, currentMiniList }) => {
    setInEditMode({
      status: true,
      rowKey: id
    })
    setPriceMin(currentMinPrice);
    setPriceMax(currentMaxPrice);
    setName(currentName)
    setMiniList(currentMiniList)
  }


  const getCategoryName = async () => {
    const res = await axios.get("/api/arkategoriak/" + path);
    setPriceList(res.data);
  };

  const getPrices = async () => {
    const res = await axios.get("/api/arak/");
    setPriceListItems(res.data);

  };

  useEffect(() => {

    // getPost()
    getCategoryName();
    getPrices();

  }, [path]);

  const handleUpdate = async ({ id }) => {
    try {
      await axios.put(`/api/arak/${id}`, {
        name,
        priceMin,
        priceMax,
        miniList,
      });
    } catch (err) { }
  };

  const handleServiceDelete = async ({id}) => {
    if (window.confirm("Biztosan szeretnéd törölni ezt a szolgáltatást?")) {
    try {
      await axios.delete(`/api/arak/${id}`);
    } catch (err) {}
  }
    getPrices();
  };

  const onSave = ({ id, currentMinPrice, currentMaxPrice, currentName, currentMiniList }) => {
    handleUpdate({ id, currentMinPrice, currentMaxPrice, currentName, currentMiniList });
    setInEditMode({
      status: false,
      rowKey: null
    })
    getPrices();
  }

  const onCancel = () => {
    // reset the inEditMode state value
    setInEditMode({
      status: false,
      rowKey: null
    })
    // reset the unit price state value
    setPriceMin(null);
    setPriceMax(null);
    setName(null)
    setMiniList(null)
  }

  const handleOnChange = () => {
    setMiniList(!miniList);
    
  };

  const handleCancel = async () => {
    window.location.replace("/admin/arlista/");
  };

  const handleDelete = async () => {
    if (window.confirm("Biztosan szeretnéd törölni ezt az árkategóriát?")) {
      try {
        await axios.delete(`/api/arkategoriak/` + path);
        window.location.replace("/admin/arlista/");
      } catch (err) { }
    }
  };



const handleModal=()=>{
  setModalOpen(true);
}

  return (
    <>
      <Header />
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      <Row>
        <Col xs={2}>
        </Col>
        <Col xs={8}>
          <div className="block aboutBlock">
            <Container>
              <legend>{priceList.name}</legend>
              
               <span onClick={handleModal} style={{color:"#0a82b5", cursor:"pointer"}}> <i class="fa fa-plus-square fa-2x" /> </span>
              

              <table className='styled-table'>
                <thead>
                  <tr>
                    <th>Szolgáltatás neve</th>
                    <th>Szolgáltatás ára (min)</th>
                    <th>Szolgáltatás ára (max)</th>
                    <th>Kiemelt</th>
                    <th></th>
                    <th></th>

                  </tr>
                </thead>
                <tbody>
                  {priceListItems.map((item) => {
                    if (item.priceCategoryID === priceList._id) {
                      return (
                        <tr key={item._id}>
                          <td>
                            {
                              inEditMode.status && inEditMode.rowKey === item._id ? (

                                <input value={item.name} style={{width:"100%"}}
                                  onChange={(event) => setName(event.target.value)}
                                />
                              ) : (
                                item.name
                              )
                            }
                          </td>
                          <td>
                            {
                              inEditMode.status && inEditMode.rowKey === item._id ? (

                                <input value={priceMin}
                                  onChange={(event) => setPriceMin(event.target.value)}
                                />
                              ) : (
                                item.priceMin
                              )
                            }
                          </td>
                          <td>
                            {
                              inEditMode.status && inEditMode.rowKey === item._id ? (

                                <input value={priceMax}
                                  onChange={(event) => setPriceMax(event.target.value)}
                                />
                              ) : (
                                item.priceMax
                              )
                            }
                          </td>
                          <td>
                            {
                              inEditMode.status && inEditMode.rowKey === item._id ? (

                                <input type={"checkbox"} checked={miniList}
                                  onChange={handleOnChange}
                                />
                              ) : (
                                <input type={"checkbox"} checked={item.miniList} />
                              )
                            }
                          </td>
                          <td>
                            {
                              inEditMode.status && inEditMode.rowKey === item._id ? (
                                <React.Fragment>
                                  <button
                                    className={"btn-success"}
                                    onClick={() => onSave({ id: item._id, currentMinPrice: item.priceMin, currentMaxPrice: item.priceMax, currentName: item.name, currentMiniList: item.miniList })}
                                  >
                                    Mentés
                                  </button>

                                  <button
                                    className={"btn-secondary"}
                                    style={{ marginLeft: 8 }}
                                    onClick={() => onCancel()}
                                  >
                                    Mégsem
                                  </button>
                                </React.Fragment>
                              ) : (
                                <button
                                  className={"btn-primary"}
                                  onClick={() => onEdit({ id: item._id, currentMinPrice: item.priceMin, currentMaxPrice: item.priceMax, currentName: item.name, currentMiniList: item.miniList })}
                                >
                                  Szerkesztés
                                </button>
                              )
                            }
                          </td>
                          <td>
                          <button
                                    className={"btn-danger"}
                                    onClick={() => handleServiceDelete({ id: item._id 
                                     })}
                                  >
                                    Törlés
                                  </button>
                          </td>

                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
              <div></div>
            </Container>
            <div>
              <button className="cancelButton" style={{ marginTop: "25px", marginRight: "25px" }} onClick={handleCancel}>
                Vissza
              </button>
              <button className="deleteButton" style={{ marginTop: "25px", marginRight: "25px" }} onClick={handleDelete}>
                Kategória törlése
              </button>

            </div>
          </div>
        </Col>
        <Col xs={2}>
        </Col>
      </Row>
    </>
  );
};

export default Arlista;
