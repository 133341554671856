import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../../Components/Header/Header";
import "./style.css";
import axios from "axios";

const KapcsolatScreen = () => {
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [opening, setOpening] = useState("");
  const [approach, setApproach] = useState("");
  // const [googlemapslink, setGooglemapslink] = useState("");

  useEffect(() => {
    const fetchSettings = async () => {
      const res = await axios.get("/api/settings/6227993c1a572abca41a1abd");
      setId(res.data._id);
      setAddress(res.data.address);
      setEmail(res.data.email);
      setPhone(res.data.phone);
      setOpening(res.data.opening);
      setApproach(res.data.approach);
      // setGooglemapslink(res.data.googlemapslink);
    };
    fetchSettings();
  }, []);

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/settings/${id}`, {
        address,
        email,
        phone,
        opening,
        approach,
        // googlemapslink,
      });
    } catch (err) {}
  };

  const handleCancel = () => {
    window.location.replace("/admin/");
  };
  return (
    <>
      <Header />
      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          <div className="block aboutBlock">
            <div>
              <div>
                <legend>Elérhetőségek</legend>
              </div>
              <div>
                <h2 className="admin-h2">Rendelő címe</h2>
                <input
                  type="text"
                  value={address}
                  className="contInputStyle"
                  autoFocus
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div>
                <h2 className="admin-h2">Rendelő telefonszáma</h2>
                <input
                  type="text"
                  value={phone}
                  className="contInputStyle"
                  autoFocus
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div>
                <h2 className="admin-h2">Rendelő e-mail címe</h2>
                <input
                  type="text"
                  value={email}
                  className="contInputStyle"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <hr className="solid-divider"></hr>
            <div>
              <legend>Nyitvatartás</legend>
            </div>
            <div>
              <h2 className="admin-h2">Hétfő-péntek</h2>
              <input
                type="text"
                value={opening}
                className="contInputStyle"
                autoFocus
                onChange={(e) => setOpening(e.target.value)}
              />
            </div>

            <hr className="solid-divider"></hr>
            <div>
              <legend>Megközelítés</legend>
            </div>
            <div>
              <h2 className="admin-h2">Megközelítés leírása</h2>
              <textarea
                value={approach}
                className="localDescInput"
                autoFocus
                onChange={(e) => setApproach(e.target.value)}
              />
            </div>

            {/* <div>
              <h2 className="admin-h2">Google Maps link</h2>
              <textarea
                value={googlemapslink}
                className="localDescInput"
                autoFocus
                onChange={(e) => setGooglemapslink(e.target.value)}
              />
            </div> */}

            <div>
              <button className="cancelButton" onClick={handleCancel}>
                Mégsem
              </button>
              <button className="createButton" onClick={handleUpdate}>
                Mentés
              </button>
            </div>
          </div>
        </Col>
        <Col xs={4}></Col>
      </Row>
    </>
  );
};

export default KapcsolatScreen;
