import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const Kezeles = ({ treatment }) => {
  const PF = "/media/images/";

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <LinkContainer to={`/fogaszati-kezelesek/${treatment.slug}`} onClick={scrollToTop}>
        <div className="cardss">
          <div className="cardd">
          <div className="innerLeft">
            <img
              className="cardd__image"
              src={PF + treatment.thumbnail}
              alt={treatment.title}
            />
            </div>
            <div className="innerRight">
              <h2 className="cardd-title">{treatment.title}</h2>
              <p className="card-text">{treatment.shortDesc}</p>
            </div>

            {/* <div>
              <Link to={`/fogaszati-kezelesek/${treatment.slug}`}>
                <Button
                  onClick={scrollToTop}
                  style={{ float: "right", marginBottom: "25px" }}
                  variant="primary"
                  size="md"
                >
                  Részletek
                </Button>
              </Link>
            </div> */}
          </div>
        </div>
      </LinkContainer>
    </>
  );
};

export default Kezeles;
