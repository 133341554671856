import React from "react";
import { Container } from "react-bootstrap";
import Meta from "../../components/Meta/Meta";

const AszfScreen = () => {
  return (
    <>
      <Meta title="Adatvédelmi tájékoztató | Oxydental" />
      <div id="about" className="block aboutBlock">
        <div className="titleHolder">
          <h2>OXYDENTAL® adatvédelmi és adatkezelési tájékoztató</h2>
        </div>
        <div className="contentHolder">
          <Container>
            <p>
              A Oxydental Hungary Kft (székhely: 1048 Budapest, Homoktövis utca
              110-112. B. ép. I. em. 7/C., cégjegyzékszám:01 09 954897,
              továbbiakban: Társaság) – mint a weboldal tulajdonosa – a jelen
              Adatvédelmi szabályzattal (a továbbiakban: Szabályzat)
              tájékoztatja a weboldalra látogatókat (a továbbiakban:
              Felhasználók) arról, hogy milyen feltételek mellett végzi a
              weboldal és a weboldalon elérhető Szolgáltatások során a
              Felhasználók személyes adatainak kezelését. Ennek részeként a
              jelen Adatvédelmi szabályzat tájékoztatást nyújt többek között a
              Társaság által történő adatkezelés módjáról, céljáról,
              jogalapjáról, tartamáról, a kezelt adatok köréről, az esetleges
              adattovábbításokról, az adatkezelésre vagy adatfeldolgozásra
              jogosult személyéről, továbbá a személyes adat érintettjének az
              adatkezelésre vonatkozó jogairól és jogorvoslati lehetőségeiről.
            </p>

            <p>
              A Társaság kezeli mindazon adatokat, amelyeket a Felhasználók a
              Weblap látogatása, használata, a Weblapon elérhető bármely
              Szolgáltatás igénybevétele során átadnak a Társaság részére,
              feltöltenek a Weblapon, valamint amely adatokat ezen tevékenységek
              során a Társaság a Felhasználóról gyűjt. Személyes adatnak minősül
              az érintett neve, e-mail címe, telefonszáma, illetve egyéb
              kiegészítő adat.
            </p>

            <p>
              A jelen szabályzat kizárólag azokra a személyes adatokra
              vonatkozik tehát, amelyeket a Felhasználók a Társaság
              rendelkezésére bocsátanak a weboldal és a hozzá köthető
              szolgáltatások érdekében, illetve amelyeket a Társaság gyűjt
              Felhasználókról. Amennyiben valamely Felhasználó önszántából
              bármely személyes adatát nyilvánosságra hozza, az ilyen
              közzétételre az Adatvédelmi szabályzat nem terjed ki.
            </p>

            <p>
              A Felhasználók a jelen Adatvédelmi szabályzat elolvasása és
              értelmezése után maguk döntenek arról, hogy megadják-e a Weblapon
              igénybe vehető Szolgáltatások használatához szükséges adatokat. Az
              adatok megadása tehát önkéntes, azonban felhívjuk a Felhasználók
              figyelmét, hogy egyes esetekben az adatok megadása nélkül a
              Felhasználó nem fogja tudni az adat megadásához kötött
              Szolgáltatást igénybe venni, illetve a Weblap valamennyi
              funkcióját használni. Ezen eseteket az adott Szolgáltatás
              megrendelésére szolgáló Weblap felületen jelezzük.
            </p>

            <p>
              A Weblap böngészésének, megfelelő használatának, valamint az egyes
              Szolgáltatások igénybevételének az a feltétele, hogy a személyes
              adatok kezelésére vonatkozó, a jelen Adatvédelmi szabályzatban
              foglalt feltételeket a Felhasználó elfogadja. A Weblap
              látogatásával, továbbá bizonyos adatkezelések esetén a Weblap
              Szolgáltatásának igénybevételével, illetve a Weblapon történő
              rendelés leadással, a Adatkezelési Szabályzat elfogadásával (ez
              utóbbi esetben az elfogadási folyamat részeként) a Felhasználó
              elismeri, hogy a jelen Adatkezelési szabályzatot megismerte és
              annak rendelkezéseit elfogadta, továbbá a Felhasználó kifejezetten
              hozzájárul a személyes adatainak a jelen Adatkezelési
              szabályzatban meghatározott célokra és feltételekkel történő
              felhasználásához és kezeléséhez.
            </p>

            <p>
              <p>Általános információk:</p>
              1.1 A Társaság a jelen Adatkezelési szabályzat szerinti személyes
              adat kezelést egyebek mellett különösen az alábbi szabályok
              alapján végzi:
            </p>

            <p>
              az információs önrendelkezési jogról és az információszabadságról
              szóló 2011. évi CXII. törvény (Infotv.);
            </p>

            <p>
              a gazdasági reklámtevékenység alapvető feltételeiről és egyes
              korlátairól szóló 2008. évi XLVIII. törvény (Grtv.);
            </p>

            <p>
              európai uniós jogi aktusok, amennyiben azok közvetlenül
              alkalmazandók, különösen a személyes adatok feldolgozása
              vonatkozásában az egyének védelméről és az ilyen adatok szabad
              áramlásáról szóló 95/45/EK irányelv;
            </p>

            <p>
              Nemzeti Adatvédelmi és Információszabadság Hatóságának ajánlásai.
            </p>

            <p>
              1.2 Amennyiben Ön a Weblapot böngészi, látogatja, a látogatói
              személyes adatainak kezelésére vonatkozó általános érvényű
              tájékoztatást a jelen pontban találja. Az Adatvédelmi szabályzat
              egyes adatkezelési helyzetekre az alábbi 2-5. pontokban további
              tájékoztatást illetve kivételeket is tartalmaz:
            </p>

            <p>
              Amennyiben a Weblapon elérhető szolgáltatásra vonatkozó
              Szolgáltatásokat igénybe veszi, úgy e Szolgáltatásokkal
              kapcsolatos jogviszonyra vonatkozóan ,személyes adatainak
              kezelésével kapcsolatos részletes tájékoztatást a 2. pontban
              talál.
            </p>

            <p>
              A Társaság cookie (süti) használatával kapcsolatosan részletes
              tájékoztatást a 3. pontban talál.
            </p>
            <p>
              Az ügyfélszolgálattal és a kommunikációval összefüggő
              adatkezelésről a 4. pont tartalmaz tájékoztatást.
            </p>

            <p>
              A Társaság üzletszerzési célú adatkezelésével kapcsolatban az 5.
              pont tartalmazza a szükséges tájékoztatást.
            </p>

            <p>
              A Társaság a Weblapon elérhető egyes Szolgáltatásokkal
              kapcsolatban további igénybevételi feltételeket is meghatározhat,
              amelyekre vonatkozóan a Szolgáltatói Weblapon, az egyes
              szolgáltatásokat bemutató tájékoztatóban vagy a Felhasználói
              Szabályzatban talál tájékoztatást.
            </p>

            <p>1.3 Az adatkezelés általános jogalapja:</p>

            <p>
              (a) az érintett előzetes, önkéntes, határozott és megfelelő
              tájékoztatás birtokában adott hozzájárulása: a Szolgáltatások
              nyújtásával kapcsolatban (lásd 2.1 pont), közvetlen üzletszerzés,
              hírlevelek küldése érdekében történő (lásd 2.8 pont), valamint
              ügyfélszolgálattal történő kommunikáció esetén (lásd 2.7 pont),
              illetve biztonság célok figyelembevétele végett;
            </p>

            <p>
              (b) jogszabály által adott felhatalmazás vagy kötelező rendelkezés
              alapján: így különösen, de nem kizárólagosan a fogyasztóvédelmi
              előírások szerinti irat- és adatmegőrzés (lásd 2.7 pont és 2.4.5
              pont), valamint a számlázással összefüggő számviteli
              nyilvántartások esetén (lásd 2.2 pont),
            </p>

            <p>
              (c) jogszabályi rendelkezéssel biztosított más jogalap: jogi
              kötelezettség teljesítése vagy jogos érdek érvényesítése céljából,
              akkor is, ha a hozzájárulás esetleg nem áll rendelkezésre, a jelen
              Adatvédelmi szabályzattal biztosított, az érintett személyes
              adatokhoz fűződő jogát veszélyeztető kockázatokat kezelő
              intézkedések mellett (lásd 2.4.5 pont).
            </p>

            <p>
              További adatkezelési célok a jelen Adatvédelmi szabályzat 2.1-2.8
              pontjaiban szerepelnek.
            </p>

            <p>
              Az adatkezelés célja: A Társaság a személyes adatokat kizárólag a
              jelen Adatkezelési szabályzatban előre meghatározott, konkrét
              adatkezelési cél elérése érdekében kezeli. A Társaság kizárólag
              olyan személyes adatot kezel, amely ezen célok megvalósításához
              szükségesek és arra alkalmasak, továbbá a Társaság a személyes
              adatokat a cél megvalósulásához szükséges mértékben és ideig
              kezeli. A személyes adatok kezelésének időtartamát jogszabály is
              előírhatja, ilyen esetben a Társaság az érintett személyes
              adatokat a vonatkozó jogszabályban meghatározott időtartam alatt
              kezeli, illetve őrzi meg. A Társaság adatkezelési céljai, amelyek
              érdekében a Társaság a Felhasználó személyes adatait kezeli:
            </p>

            <p>
              A Weblap működése során a Felhasználók és a Társaság között
              fennálló jogviszonyokhoz kapcsolódóan a Társaság jogainak
              gyakorlása illetve kötelezettségeinek teljesítése;
            </p>

            <p>
              A felhasználói jogviszonyokkal kapcsolatos érdekérvényesítés
              (például abban az esetben, ha egy Felhasználó hirdetésével vagy
              egyéb tevékenységével kapcsolatosan panasz érkezik a Társaság
              részére, vagy eljárás indul a Felhasználó ellen, amelynek során az
              eljáró hatóságok adatszolgáltatást igényelnek a Társaságtól);
            </p>

            <p>
              A Weblap működéséhez kapcsolódó kommunikáció (ezen belül a
              Társaság és a Felhasználó között folytatott kommunikáció);
            </p>

            <p>Ügyfélszolgálati funkciók ellátása;</p>

            <p>Hírlevelek küldése, közvetlen üzletszerzés végzése;</p>

            <p>
              A Weblap látogatottságának számszerűsíthetősége, az ott elérhető
              Szolgáltatások kapcsán statisztikák készítése;
            </p>

            <p>A Szolgáltatások védelme az illetéktelen behatolás ellen;</p>

            <p>
              A Weblap felhasználói interfészének ergonómiájának (azaz a
              felhasználóbarát kelezési felület kialakítása és működtetése), az
              azokon keresztül elérhető Szolgáltatásoknak a fejlesztése; a
              Weblapon reklámot elhelyező partnerek kiszolgálása annak
              érdekében, hogy az általuk a Weblapon elhelyezett reklámok
              megfelelően működjenek, illetve releváns reklámok kerüljenek
              megjelentetésre.
            </p>

            <p>
              A Weblap használata során a Társaság az alábbi további adatokat
              gyűjti a látogatókról, illetve Felhasználókról: a Felhasználók IP
              címe és forgalmi aktivitásuk (tehát a Weblapon keresztül végzett
              tevékenységük) adatai kerülnek rögzítésre a Társaság által, annak
              érdekében, hogy a Weblap látogatottsága számszerűsíthető legyen,
              és az esetlegesen felmerülő hibákat és betöréseket a Társaság
              azonosíthassa. Ezen adatok tekintetében a Felhasználók személyes
              adatokkal kapcsolatos érdekeit védi egyrészt az, hogy a
              Felhasználó ezen adatkezelést megtilthatja, másrészt az, hogy ezen
              adatokat a Társaság nem kapcsolja össze a Felhasználó azonosító
              adataival, és ezen adatok továbbítására csak anonimizált – vagyis
              személyes adatnak nem minősülő – formában kerül sor.
            </p>

            <p>
              1.4 Adatbiztonság: A Társaság biztosítja az általa kezelt
              személyes adatok biztonságát a megfelelő technikai és szervezési
              intézkedésekkel, valamint eljárási szabályok kialakításával, így a
              személyes adatokat megfelelő intézkedésekkel védi különösen a
              jogosulatlan hozzáférés, megváltoztatás, továbbítás,
              nyilvánosságra hozatal, törlés vagy a véletlen megsemmisülés
              ellen. A személyes adatokat tartalmazó adatbázisokat titkosítva
              tároljuk, és az adatbázishoz csak a Társaság azon alkalmazottai
              férnek hozzá, akiknek ügyfélszolgálati, hibaelhárítással, vagy
              érdekérvényesítéssel összefüggő munkakörök miatt erre szükségük
              van. Minden esetben, amikor egy adott cél eléréséhez anonimizált
              adatok is elegendők, a Társaság elvégzi az anonimizálást. Az
              adatkezelések részletes feltételeiről az alábbi pontokban
              található további tájékoztatás.
            </p>

            <p>
              2.1.2 Az adatkezelés célja a fenti adatok tekintetében – a 2.4
              pontban meghatározott célokon kívül – a rendelések menedzselése.
            </p>

            <p>
              2.1.3 Az adatkezelés időtartama: felhasználói fiók létrehozása
              esetén legalább a felhasználói fiók fennállásáig, annak hiányában
              az adott Szolgáltatásra vonatkozó határozott időtartamig, illetve
              a Szolgáltatás nyújtásához feltétlenül szükséges időtartamig, vagy
              a Felhasználó hozzájárulásának visszavonásáig. Kivételt képez a
              fenti határidők alól, amennyiben a Társaságnak törvényi
              kötelezettsége egyes adatok kezelése (lásd az alábbi 2.4.5 pont).
              Ebben az esetben a törvényes cél megvalósulásáig kezeli az
              adatokat.
            </p>

            <p>2.1.4 Az adatkezelés jogcíme:</p>

            <p>
              (a) az érintett előzetes, önkéntes, határozott és megfelelő
              tájékoztatás birtokában adott hozzájárulása: a Szolgáltatások
              nyújtásával kapcsolatban (lásd 2.1 pont), közvetlen üzletszerzés,
              hírlevelek küldése érdekében történő (lásd 2.7 pont), valamint
              ügyfélszolgálattal történő kommunikáció esetén (lásd 2.6 pont);
            </p>

            <p>
              (b) jogszabály által adott felhatalmazás vagy kötelező rendelkezés
              alapján: így különösen, de nem kizárólagosan a fogyasztóvédelmi
              előírások szerinti irat- és adatmegőrzés (lásd 2.6 pont és 2.4.5
              pont), valamint a számlázással összefüggő számviteli
              nyilvántartások esetén (lásd 2.2 pont),
            </p>

            <p>
              (c) jogszabályi rendelkezéssel biztosított más jogalap: jogi
              kötelezettség teljesítése vagy jogos érdek érvényesítése céljából,
              akkor is, ha a hozzájárulás esetleg nem áll rendelkezésre, a jelen
              Adatvédelmi szabályzattal biztosított, az érintett személyes
              adatokhoz fűződő jogát veszélyeztető kockázatokat kezelő
              intézkedések mellett (lásd 2.4.5 pont).
            </p>

            <p>2.2 Számlaadás</p>

            <p>
              2.2.1 A Szolgáltatások megrendelése során kezelt adatok köre a 2.1
              pontban meghatározott adatok, továbbá:
            </p>

            <p>Számlázási név</p>

            <p>Számlázási cím</p>

            <p>
              2.2.2 Az adatkezelés célja a fenti adatok tekintetében – a 2.4
              pontban meghatározott célokon kívül – a Szolgáltatás díjának
              számlázása, beszedése, fizetés lebonyolításában való közreműködés,
              valamint az azzal kapcsolatos követelés érvényesítése.
            </p>

            <p>
              2.2.3 Az adatkezelés időtartama: azon adatok tekintetében, amelyek
              a Szolgáltatással kapcsolatos számviteli bizonylatokon (számlákon)
              szerepelnek (a Felhasználó által megadott számlázás név, cím,
              továbbá a megrendelt Szolgáltatás), valamint a számviteli
              szabályok által ezek alátámasztására szolgáló adatok a
              számvitelről szóló 2000. évi C. törvény értelmében az adatok
              keletkezésétől számított 8 évig tárolandóak, amely adatkezelés
              kötelező.
            </p>

            <p>
              2.2.4 Az adatkezelés jogcíme: a számvitelről szóló 2000. évi C.
              törvény által a számviteli bizonylatok és az azok alátámasztására
              szolgáló adatok megőrzésére előírt szabályok.
            </p>

            <p>
              2.3.3 Az adatkezelés időtartama: legalább a felhasználói fiók
              fennállásáig, illetve a Szolgáltatás nyújtásához feltétlenül
              szükséges időtartamig, vagy a Felhasználó hozzájárulásának
              visszavonásáig. Kivételt képez a fenti határidők alól, amennyiben
              a Társaságnak törvényi kötelezettsége egyes adatok kezelése (lásd
              a 2.2 és 2.4.5 pontot). Ebben az esetben a törvényes cél
              megvalósulásáig kezeli az adatokat.
            </p>

            <p>
              2.3.4 Az adatkezelés jogcíme: A Felhasználó hozzájárulása, kivéve
              a számviteli bizonylat kiállításával kapcsolatos (lásd 2.2 pont)
              és a Szolgáltatásokkal kapcsolatos egyes igények érvényesítéséhez
              kapcsolódó (lásd 2.4.5 pont) adatkezelést, amely esetekben az ezen
              pontokban meghatározott jogszabály alapján törvényi kötelezettség
              illetve jogszabályi felhatalmazás az adatkezelés jogalapja.
            </p>

            <p>
              2.4 Valamennyi Szolgáltatásra irányadó adatkezelési információk
            </p>

            <p>
              2.4.2 A 2.1, 2.2. és 2.3 pontokban meghatározott adatkezelések
              általános célja – az ott meghatározottakon túl – a
              Szolgáltatásokra vonatkozó jogviszonyok létrehozása, az azokból
              eredő jogok gyakorlása, kötelezettségek teljesítése, a
              Szolgáltatás nyújtása, működés ellenőrzése és a visszaélések
              megakadályozása, a Felhasználók azonosítása és egymástól való
              megkülönböztetése, a Felhasználóval való kapcsolattartás,
              statisztikák készítése, forgalom és a felhasználói aktivitás és
              szokások figyelemmel kísérése és rögzítése, ezáltal a Weblap
              Felhasználói részére kényelmes vásárlási élmény nyújtása.
            </p>

            <p>
              2.4.3 Az adatkezelés időtartama: Amennyiben az adatkezelés
              önkéntes és a Felhasználó hozzájárulásán alapul, a Felhasználó
              hozzájárulásának visszavonása esetén a Társaság megszünteti az
              adat kezelését, kivéve az alábbi eseteket: amennyiben a
              Felhasználó igénybe veszi azon Szolgáltatásokat, amely nyújtásához
              az adott adatok a jelen Adatvédelmi szabályzat szerint kötelezőek,
              illetve törvényben meghatározott esetekben azt követően a cél
              megvalósulásáig.
            </p>

            <p>
              2.4.4 Üzenetküldéssel kapcsolatos adatkezelés: Amennyiben a
              Felhasználók a Társaság által üzemeltetett üzenetküldő rendszert
              alkalmazzák, annak használatával hozzájárulásukat adják az
              üzenetküldő rendszeren történő üzeneteik Társaság által történő
              rögzítéséhez, kezeléséhez és esetleges ellenőrzéséhez. Az
              adatkezelés célja a Felhasználók kommunikációinak ellenőrzése és
              megőrzése, visszaélések megakadályozása, a Felhasználói Szabályzat
              szerinti működés ellenőrzése, Szolgáltatás nyújtása. Az
              üzenetküldő rendszeren keresztül küldött kommunikáció alatt érteni
              kell valamennyi írásbeli üzenetet, fényképet, videót, bármely
              csatolmányt.
            </p>

            <p>
              Az adatkezelés időtartama: az adatkezelés céljának
              megvalósulásáig, vagy a jogszabályban meghatározott egyéb
              időpontig, de legfeljebb a kommunikáció törlésének
              kezdeményezésétől számított 15. napig, vagy a Felhasználó
              hozzájárulásának visszavonásáig.
            </p>

            <p>
              2.4.5 Igényérvényesítéssel kapcsolatos adatkezelés: A Társaság a
              Felhasználóval szemben történő igényérvényesítés, a Társaság vagy
              harmadik személy jogos érdekének védelme és érvényesítése céljából
              az érintett jogviszony megszűnésétől számított 24 hónap időtartam
              alatt 2.1, 2.2. és 2.3 pontokban meghatározott adatokat, valamint
              a Felhasználó megkeresésének ,kommunikációjának tartalmát, amely a
              Társaság felé történik, tárolja és megőrzi, az erre a célra
              szolgáló zárolt adatbázisban. Az adatbázishoz kizárólag a Társaság
              ügyvezetője és az érdekérvényesítésben részt vevő, erre
              felhatalmazott munkavállalója vagy megbízottja fér hozzá.
            </p>

            <p>
              Az igényérvényesítéssel kapcsolatos adatkezelés jogalapja: a
              személyes adatok feldolgozása vonatkozásában az egyének védelméről
              és az ilyen adatok szabad áramlásáról szóló 1995. október 24-i
              95/46/EK európai parlamenti és tanácsi irányelv (Adatvédelmi
              irányelv) 7. cikk f) pontja, amelynek értelmében személyes adat
              kezelhető abban az esetben, ha az adatkezelés az adatkezelő vagy
              az adatot megkapó harmadik fél, vagy felek jogszerű érdekének
              érvényesítéséhez szükséges, feltéve, hogy ezeknél az érdekeknél
              nem magasabb rendűek az érintettek személyes adatok kezelése
              vonatkozásában a magánélet tiszteletben tartásához való joguk. A
              Társaság a jelen pont alapján kizárólag abban az esetben kezeli a
              2.1.1 és a 2.3.1 pontban meghatározott személyes adatokat,
              amennyiben azok kezelése egy a Weblapon feladott rendelést vagy
              igénybe vett szolgáltatást kapcsán az adott személy vagy harmadik
              személy, hatóság által tett panasz, bejelentés, jogsérelem miatt
              történő igényérvényesítés miatt szükségessé válik annak érdekében,
              hogy a jogsértés, sérelem megtörténte, az esetleges felelős
              személye megállapítható legyen és a sérelem orvoslásra
              kerülhessen.
            </p>

            <p>2.5 Cookie technológia alkalmazása</p>

            <p>
              2.5.1 A Cookie technológia leírása, a kezelt adatok köre: A
              Weblapon tett látogatás során, illetve az ott elérhető
              Szolgáltatások igénybevételekor, a Felhasználó által adott
              hozzájárulás esetén egy vagy több karaktersorozatból álló
              azonosító file („süti” vagy „cookie”) kerül megküldésre és
              elhelyezésre a Felhasználó elektronikus hírközlő végberendezésén,
              számítógépén. A cookie által a Felhasználó böngészője egyedileg
              azonosíthatóvá válik. A Társaság ezek alapján a Felhasználóknak a
              Weblap használatával, böngészési szokásaival kapcsolatos adatait
              kezeli, és tárolja a munkamenet során megadott adatokat. A
              cookie-k használatát a Felhasználó a Weblapon megjelenő felugró
              ablak segítségével hagyhatja jóvá.
            </p>

            <p>
              A cookie-k lehetnek „állandó” vagy „ideiglenes” típusúak. Az
              állandó cookie-t a böngésző egy meghatározott időpontig,
              lejáratáig tárolja, feltéve, hogy azt a Felhasználó nem törli vagy
              tiltja le (lásd lejjebb), míg az ideiglenes cookie a böngésző
              becsukásával, az adott munkamenet végével, automatikusan törlődik.
              Amennyiben a cookie állandó (vagyis nem ideiglenes) jelleggel
              kerül elhelyezésére, az adatokat a Szolgáltató 365 napig, illetve
              addig kezeli, amíg a Felhasználó a hozzájárulását jelen
              Adatvédelmi Szabályzat feltételei szerint vissza nem vonja. A
              jelen pontban meghatározott célból kezelt adatokat a Társaság
              haladéktalanul törli, ha az adatkezelési cél megszűnt, vagy a
              Felhasználó így rendelkezik.
            </p>

            <p>
              2.5.2 Az adatkezelés célja és módja: A cookie-k a felhasználói
              élmény testre szabásához, a személyre szabott kiszolgálás
              érdekében, a Weblapon elérhető, információs társadalommal
              összefüggő (elektronikus kereskedelmi) szolgáltatások személyre
              szabott nyújtása érdekében, a Felhasználók azonosítása, a részükre
              kedvezőbb, érdeklődésüknek megfelelő tartalmak kialakítása és
              elérhetővé tétele érdekében kerülnek alkalmazásra. A cookie-k
              által felvett adatok kezelésének célja továbbá a látogatottsági
              információk gyűjtése, statisztikák készítése.
            </p>

            <p>
              A cookie-k általi adatkezelés célja a felhasználói élmény testre
              szabása, így azok a személyre szabott kiszolgálás érdekében, a
              Weblapon elérhető, információs társadalommal összefüggő
              (elektronikus kereskedelmi) szolgáltatások személyre szabott
              nyújtása érdekében, továbbá a Felhasználók azonosítása, a részükre
              kedvezőbb, érdeklődésüknek megfelelő tartalmak kialakítása és
              elérhetővé tétele érdekében, valamint azért kerülnek alkalmazásra,
              hogy az azonosítást követő bejelentkezést követően a Felhasználó a
              belépéshez kötött funkciókat használhassa. A cookie-k által
              felvett adatok kezelésének célja továbbá látogatottsági
              információk gyűjtése, statisztikák készítése. A Társaság a
              Weblapról begyűjtött cookie-információk segítségével követni tudja
              az új és visszatérő látogatók számát, statisztikákat tud készíteni
              a Weblapon érzékelt aktivitásról, a Felhasználók által felkeresett
              oldalakról, így jobban megismerheti a használati szokásokat,
              továbbá lehetősége nyílik információkat elemezni, hogy ezzel is
              elősegíthesse a Weblap szolgáltatásainak fejlesztését és
              javítását.
            </p>

            <p>
              A Weblap, illetve a Weblapon keresztül a Társaság külső
              szolgáltatók (Google Analytics/Adwords Remarketing, Facebook)
              követő kódokat használnak annak érdekében, hogy a Felhasználókat
              később a Google Display hálózatába tartozó, és más weboldalakon
              személyre szabott hirdetésekkel érjék el. A külső szolgáltatók
              ezen cookie-k segítségével tárolják, ha a Felhasználó korábban már
              látogatást tett egy hirdető webhelyén, és ez alapján hirdetéseket
              jelenítenek meg a Felhasználó számára, a külső szolgáltató
              partnereinek internetes webhelyein, így a Weblapon tett látogatása
              alapján hirdetést jeleníthetnek meg a Felhasználó számára. A
              Weblap vizuális hirdetéseken alapuló Google Analytics funkciókat
              alkalmaz. A Weblap hirdetéseit külső szolgáltatók is
              megjeleníthetik internetes webhelyeiken. A Weblap és külső
              szolgáltatói saját cookie-kat és harmadik féltől származó
              cookie-kat használnak együttesen a Felhasználók által tett korábbi
              látogatások alapján történő tájékozódásra, a hirdetések
              optimalizálására és részükre történő megjelenítésére.
            </p>

            <p>A Weblapon alkalmazott cookie-k típusuk, funkcióik szerint:</p>

            <p>analítika, követés cookie</p>

            <p> weboldalon keresztül követés, hirdetések személyre szabása,</p>

            <p>bejelentkezési, felhasználó azonosító session cookie</p>

            <p>
              2.5.3 Az adatkezelés jogcíme: A cookie-k által gyűjtött adatok
              kezelését a Társaság az igénybevevő önkéntes hozzájárulása alapján
              kezeli. Azonban amennyiben a cookie-k használatát a Felhasználó
              letiltja, illetve annak elhelyezéséhez és használatához nem járul
              hozzá, abban az esetben nem feltétlenül biztosított a Weblap
              valamennyi funkciójának zavartalan használata, ez bizonyos
              Szolgáltatások igénybevételét korlátozhatja vagy megakadályozhatja
            </p>

            <p>
              Az adatkezelés önkéntes hozzájáruláson alapul: Az adatkezelés
              jogalapja az Infotv. 5. § (1) a.) pontja alapján az érintett
              önkéntes hozzájárulása, továbbá az elektronikus hírközlésről szóló
              2003. évi C. törvény 155.§ (4) bekezdése szerinti hozzájárulás.
            </p>

            <p>
              A Felhasználó a böngészőjében képes törölni a sütit a saját
              számítógépéről, valamint annak alkalmazását le is tilthatja. A
              sütiket jellemzően a böngészők Eszközök / Beállítások menüjében az
              Adatvédelem beállítások alatt lehet kezelni. A legtöbb böngésző
              alapértelmezés szerint automatikusan elfogadja a cookie-kat, de a
              böngésző biztonsági beállításának (tiltás / visszavonás)
              módosításával a Felhasználó elutasíthatja, illetve kiválogathatja
              azokat. A Felhasználó a Google Analytics hirdetési funkcióit és a
              Google Display Hálózat hirdetéseit a Google fiókjába történő
              belépésével tilthatja le, vagy szabhatja személyre.
            </p>

            <p>
              2.6.1 A kezelt adatok köre: Fogyasztónak minősülő személy által
              tett panaszbejelentések kivizsgálása esetén az alábbiakban
              felsorolt adatok kezelése – a fogyasztóvédelemről szóló 1997. évi
              CLV. törvény (Fgytv.) 17/A. § alapján – kötelező, minden egyéb
              esetben az ügyfélszolgálat igénybevételével önkéntes, és a
              Felhasználó önkéntes hozzájárulásán alapul:
            </p>

            <p>
              <ul>
                <li>
                  a) panasz bejelentőjének neve, címe, továbbá a panasz
                  bejelentésekor a panaszos által megadott egyéb adatok;
                </li>
                <li>b) panasz előterjesztésének helye, ideje, módja;</li>
                <li>c)panasz részletes leírása;</li>
                <li>
                  d) panaszos által bemutatott egyéb bizonyítékok, ezek
                  jegyzéke;
                </li>
                <li>e) a panaszos számára megküldött válaszlevél másolata;</li>
                <li>f) panaszos telefonszáma;</li>
                <li>
                  g) beszélgetés által közölt adatok, amennyiben az fentiek
                  szerinti telefonszámot a panaszos használja.
                </li>
              </ul>
              Amennyiben a panaszos szóbeli panaszt jelent be és annak azonnali
              kivizsgálása nem lehetséges, vagy ha a Felhasználó a panasz
              Társaság általi kezelésével nem ért egyet, akkor az a)-e) pontban
              meghatározott adatok jegyzőkönyvben kerülnek rögzítésre.
            </p>

            <p>
              2.6.2 Az adatkezelés célja: Az ügyfélszolgálat igénybevétele során
              megadott adatok kezelésének célja a panasz, illetve a
              bejelentésben feltett kérdés megválaszolása, kivizsgálása,
              kezelése, továbbá a Felhasználó tájékoztatása a Társaság érdemi
              válaszáról.
            </p>

            <p>
              2.6.3. Az adatkezelés időtartama: A Társaság a panaszokról felvett
              jegyzőkönyveket és kapcsolódó adatokat, valamint a Fgytv. 17/A. §
              (5) bekezdése alapján kezelt adatokat – a Fgytv. 17/A. § (7) és
              17/B. § (3) bekezdése alapján – a panasz bejelentésétől számított
              öt évig tárolja.
            </p>

            <p>
              2.6.4. Az adatkezelés jogcíme: az Ekertv. 13/A. § (3) bekezdése,
              nem fogyasztói panaszok esetén az érintett hozzájárulása, amelyet
              a bejelentéssel megadottnak tekint a Társaság, fogyasztói panaszok
              esetén a jegyzőkönyvek vonatkozásában a Fgytv. 17/A. § (5)
              bekezdése, amely szerint a hivatkozott adatkezelés kötelező.
            </p>

            <p>
              2.7. Közvetlen üzletszerzési célra a Felhasználó hozzájárulása
              alapján kezelt adatok:
            </p>

            <p>
              2.7.1 Az adatkezelés feltétele: A Társaság a Felhasználó adatait
              hozzájáruló nyilatkozata esetén használhatja fel közvetlen
              üzletszerzési célra. A Felhasználók az adataik megadásával a
              rendelés feladásakor, vagy felhasználói fiók létrehozásakor, vagy
              hírlevélre történő jelentkezéskor a megfelelő üres rubrika
              bejelölésével hozzájárulásukat adhatják ahhoz, hogy a Társaság a
              megadott elérhetőségeken, e-mailen, vagy automatizált
              hívórendszer/eszköz segítségével direkt marketing (közvetlen
              üzletszerzési célú) ajánlattal, elektronikus hirdetéssel (e-mail,
              SMS stb.), hírlevelekkel felkeresse őket. A Társaság fenntartja a
              jogot, hogy e megkeresésekben saját Szolgáltatásainak és
              tevékenységének bemutatásán túl vele szerződéses kapcsolatban álló
              reklámozók hirdetését is belefoglalja.
            </p>

            <p>2.7.2 A kezelt adatok köre:</p>

            <p>Név</p>
            <p>E-mail cím</p>
            <p>Telefonszám</p>
            <p>Nem</p>
            <p>Születési év</p>

            <p>
              2.7.3 Az adatkezelés időtartama: Az adatokat a Társaság a
              Felhasználó hozzájárulásának visszavonásáig kezeli.
            </p>

            <p>
              A hozzájárulás valahol bármikor ingyenesen, korlátozástól és
              indokolástól mentesen visszavonható. A hozzájárulás visszavonható
              továbbá a Társaságnak címzett és a Társaság székhelyére postai
              úton eljuttatott nyilatkozattal is. A közvetlen üzletszerzési
              célból kezelt adatok körébe tartoznak még a felhasználási
              szokásokra vonatkozó, előfizetői hozzájárulással az Adatvédelmi
              szabályzat általános szabályai szerint kezelt szerinti adatok.
            </p>

            <p>
              A Társaság felhívja a figyelmet arra, hogy nem tartoznak a jelen
              pont szerinti kommunikáció körébe a Társaság által, a Szolgáltatás
              igénybevétele kapcsán küldött rendszerüzenetek, amelyek közlése a
              Felhasználó érdekében szükséges, vagy a Szolgáltatás
              igénybevételére vonatkozó, vagy azzal kapcsolatos információt
              tartalmaznak, vagy technikai jellegű tájékoztatást nyújtanak, vagy
              amelyek a jogviszony lényeges elemeire vonatkozó tájékoztatást
              tartalmaznak.
            </p>

            <p>2.7.4 Az adatkezelés jogcíme: a Felhasználó hozzájárulása.</p>

            <p>
              Adattovábbítások, az adatkezelésre és feldolgozásra jogosultak
              köre
            </p>

            <p>
              A Társaság a Felhasználók személyes adatait a jelen Adatkezelési
              szabályzatban meghatározott esetben és célból a Szolgáltatások
              nyújtása és az igénybevételük és teljesítésük biztosítása
              érdekében, előzetesen meghatározott harmadik személyek részére
              továbbítja. Továbbá a Társaság – a Felhasználó tájékoztatása
              mellett és a Nemzeti Adatvédelmi és Információszabadság Hatóság
              nyilvántartásába történő bejelentkezést követően – az erre
              irányuló szerződés alapján átadja a személyes adatokat
              adatfeldolgozók részére a Szolgáltatás teljesítése érdekében.
            </p>

            <p>
              Egyes esetekben a partnerek kifejezetten a Társaság instrukciói
              alapján, azt meg nem haladó körben végeznek adatfeldolgozási
              műveleteket (pl. szerver adatok tárolása, ügyvitel) a Társaság
              javára; ezen partnerek adatfeldolgozónak minősülnek, és
              igénybevételük külön hozzájárulást nem igényel. Más esetekben a
              partnerek az átadott személyes adatok felhasználásával, az
              adatkezeléssel összefüggésben saját döntéseket hozva nyújtják
              saját – a Társaság Szolgáltatásával összefüggő – szolgáltatásukat.
              E partnerek adatkezelőnek minősülnek, akik részére az
              adattovábbítás hozzájárulást igényel, amely hozzájárulást a
              Felhasználó a jelen Adatkezelési szabályzat rendelkezései szerint
              adja meg. Mind az adatfeldolgozó, mind az adatkezelő partnerekkel
              a Társaság olyan szerződéseket köt, amelyekben e partnerek
              vállalják a részükre átadott személyes adatok vonatkozó
              jogszabályoknak megfelelő kezelését és titoktartást.
            </p>

            <p>
              A Felhasználó Weblapra történő látogatásával illetve a Weblap
              szolgáltatásainak igénybevételekor hozzájárul személyes adatainak
              a lentiek szerint adatkezelő partnerekhez történő továbbításához,
              továbbá tudomásul veszi a tájékoztatást személyes adatainak a
              Társaság által igénybe vett adatfeldolgozók számára történő
              továbbadásához:
            </p>
            <p>
              Amennyiben az arra feljogosított hatóságok a vonatkozó
              jogszabályban előírt módon a Társaságtól személyes adatot kérnek,
              a Társaság törvényi kötelezettségének eleget téve átadja a kért
              személyes adatokat.
            </p>

            <p>
              A Társaság fenntartja a jogot, hogy – változatlan célokra – időről
              időre adatfeldolgozási műveletet végző szerződéses partneri körét
              felülvizsgálja, és szükség esetén új partnerekkel létesítsen
              adatfeldolgozási műveleteket (is) tartalmazó szolgáltatásokra
              szerződéses kapcsolatot. A Társaság a mindenkori szerződéses
              partneri állományról a Felhasználó kérésére tájékoztatást ad és az
              Adatvédelmi szabályzat mindenkori újabb változataiban a partneri
              kört aktualizálja. A személyes adatok továbbításával érintett
              adatkezelők személyi körében bekövetkező változás esetén a
              Felhasználó, megfelelő tájékoztatást követően, a Weblapon
              keresztül ad hozzájárulást adatai továbbításához.
            </p>

            <p>
              A Felhasználó (érintett) jogai a Társaság adatkezelésével
              kapcsolatban
            </p>

            <p>
              Az Infotv. 14-18. §-ai alapján az érintett (Felhasználó) jogosult
              arra, hogy a Társaság által kezelt személyes adatai kezeléséről
              tájékoztatást kérjen; azok helyesbítését kérje; továbbá törlését
              vagy zárolását kérje. A Felhasználó jogosult az önkéntes
              hozzájárulásán alapuló, a Szolgáltatásra vonatkozó szerződés
              létrejöttétől és teljesítésétől független adatkezeléseket
              megtiltani. Ilyen esetben is megőrzi azonban a társaság a
              személyes adatokat a fenti 2.4.5 pontban foglalt
              igényérvényesítési célból, az ott meghatározott 24 hónapos
              időtartam végéig.
            </p>

            <p>
              A Társaság a Felhasználóra vonatkozó személyes adatokat törli, ha:
            </p>

            <p>az adatkezelés jogellenes;</p>

            <p>a Felhasználó az adatok törlését kéri;</p>

            <p>
              A Felhasználó személyes adata hiányos vagy téves, és ez az állapot
              jogszerűen nem orvosolható, feltéve, hogy a törlést törvény nem
              zárja ki;
            </p>

            <p>az adatkezelés célja megszűnt;</p>

            <p>
              azt bíróság vagy a Nemzeti Adatvédelmi és Információszabadság
              Hatóság elrendelte.
            </p>

            <p>
              A Felhasználó törlés iránti kérelme kizárólag a Felhasználó
              hozzájárulása alapján kezelt adatok törlésére vonatkozhat, és az
              nem érinti a jogszabály által elrendelt kötelező adatkezeléssel
              érintett adatok körét. A Társaság a Felhasználó személyes
              adatainak kezelésére továbbá hozzájárulás nélkül illetve a
              hozzájárulás visszavonására vonatkozó kérelmét követően is
              jogosult az Infotv. 6 §-a alapján, amennyiben az a Társaság mint
              adatkezelő jogi kötelezettségeinek teljesítése vagy a Társaság
              jogos érdekének érvényesítése céljából szükséges, és nem áll fenn
              olyan körülmény, amelyre tekintettel az érintett személyes adatok
              védelméhez fűződő joga meghaladná a Társaság adatkezeléssel
              összefüggő érdekét.
            </p>

            <p>
              Törlés helyett a Felhasználó kérheti személyes adatainak
              zárolását. Adatzárolás esetén a Felhasználó személyes adatai olyan
              azonosító jelzéssel kerülnek ellátásra, amelyek az adatok további
              kezelését végleges vagy határozott időre korlátozzák.
            </p>

            <p>
              A jelen pont szerinti kérelmeket a Társaság az Ügyfélszolgálati
              elérhetőségeken fogadja.
            </p>

            <p>
              A Felhasználó bármikor kérhet tájékoztatást a Szolgáltatói
              adatkezelésről, így különösen az adatkezelés céljáról,
              jogalapjáról, vagy az adatfeldolgozó személyéről. A Társaság
              köteles a kérelem benyújtásától számított legrövidebb idő alatt,
              legfeljebb 25 napon belül, közérthető formában írásban megadni a
              tájékoztatást. A Felhasználó az adatkezelésről évente egy
              alkalommal ingyenesen kérhet tájékoztatást. Amennyiben az érintett
              ugyanarra az adatkörre vonatkozóan újabb tájékoztatást kér az
              adott évben, úgy a Társaság jogosult költségtérítést kérni. A
              Felhasználó által már megfizetett költségtérítés összegét a
              Társaság a Felhasználó részére visszatéríti, amennyiben a
              Felhasználó személyes adatainak helyesbítésére kerül sor vagy az
              adatok törlésre vagy helyesbítésre kerültek, illetve ha az Infotv.
              ezt előírja. Amennyiben a Társaság a kérelmet nem találja
              megalapozottnak és annak teljesítését megtagadja, a Társaság
              írásban közli az érintettel, hogy a felvilágosítás megtagadására e
              törvény mely rendelkezése alapján került sor. A felvilágosítás
              megtagadása esetén a Társaság tájékoztatja az érintettet a
              bírósági jogorvoslat, továbbá a Hatósághoz fordulás lehetőségéről.
            </p>

            <p>
              A Felhasználó kérheti adatai helyesbítését, ha azok nem felelnek
              meg a valóságnak.
            </p>

            <p>
              A Felhasználó a fentieken túl tiltakozhat személyes adatának
              kezelése ellen, ha a személyes adatok kezelése vagy továbbítása
              kizárólag az adatkezelőre vonatkozó jogi kötelezettség
              teljesítéséhez vagy az adatkezelő vagy harmadik személy jogos
              érdekének érvényesítéséhez szükséges (kivéve kötelező adatkezelés
              esetén), vagy ha a személyes adat felhasználása vagy továbbítása
              közvetlen üzletszerzés, közvélemény-kutatás vagy tudományos
              kutatás céljára történik. A Társaság a tiltakozást kérelem
              benyújtásától számított legrövidebb időn belül, de legfeljebb 15
              napon belül megvizsgálja, annak megalapozottsága kérdésében hozott
              döntéséről a Felhasználót írásban tájékoztatja. Amennyiben a
              Társaság a Felhasználó tiltakozásának megalapozottságát
              megállapítja, az adatkezelést megszünteti, és az adatokat zárolja,
              valamint a tiltakozásról, továbbá az annak alapján tett
              intézkedésekről értesíti mindazokat, akik részére a tiltakozással
              érintett adatot továbbította, és akik kötelesek intézkedni a
              tiltakozási jog érvényesítése érdekében.
            </p>

            <p>Jogorvoslat</p>

            <p>
              Az érintett a jogainak megsértése esetén jogosult a Nemzeti
              Adatvédelmi és Információszabadság Hatóságnál (cím: 1125 Budapest,
              Szilágyi Erzsébet fasor 22/c; telefon: +36-1-391-1400; e-mail:
              ugyfelszolgalat@naih.hu) bejelentést tenni vagy – választása
              szerint – a Társaság székhelye vagy az érintett a lakóhelye vagy
              tartózkodási helye szerinti törvényszékhez fordulni.
            </p>

            <p>
              Amennyiben a Felhasználó kérelme a Társaság által elutasításra
              kerül, azzal szemben a Felhasználó a Nemzeti Adatvédelmi és
              Információszabadság Hatóságnál bejelentést tehet, vagy a
              lakóhelye, tartózkodási helye szerinti bírósághoz fordulhat.
              Amennyiben a Társaság tiltakozása tárgyában hozott döntésével nem
              ért egyet, vagy a Társaság határidőn belül nem dönt, a
              Felhasználó, a döntés közlésétől, vagy az elmulasztott határidő
              utolsó napjától számított 30 napon belül a lakóhelye vagy
              tartózkodási helye szerinti bírósághoz fordulhat.
            </p>

            <p>Fiatalkorúak adatvédelme</p>
            <p>
              Szülői beleegyezés nélkül tudatosan nem gyűjtünk adatot tizenhárom
              (13) éves kor alatti vagy a vonatkozó jogszabályok értelmében
              annak megfelelő fiatalkorútól. Arra ösztönözzük a szülőket (vagy a
              gyámot), hogy SZOLGÁLTATÁSUNK használatakor aktívan vegyék ki
              részüket a fiatalkorú online tevékenységeiből és érdeklődéséből.
            </p>
            <p>
              Ha kiskorú vagy, a SZOLGÁLTATÁS használatához kérj szülői
              beleegyezést. A szülői beleegyezésről szóló adatokat nekünk is
              küldheted. Szülőd (vagy gondviselőd) bármikor visszavonhatja
              korábbi beleegyezését, illetve kérheti az adatok elérését,
              javítását, zárolását vagy törlését.
            </p>

            <p>Záró rendelkezések</p>

            <p>
              Amennyiben nem ért egyet a fentiekkel, kérjük, ne használja a
              Weblapot.
            </p>

            <p>
              Amennyiben az adatvédelemmel kapcsolatban további kérdései
              lennének, kérjük, lépjen kapcsolatba munkatársunkkal.
            </p>

            <p>
              A Társaság fenntartja magának a jogot jelen Adatvédelmi szabályzat
              egyoldalú megváltoztatására. Ilyen esetben a változás tényét a
              Társaság honlapján jelzi. Az Adatvédelmi szabályzat mindenkori
              aktuális változata a Weblap megfelelő menüpontjában mindenkor
              elérhető.
            </p>

            <p>
              <ul>
                <li>Név: Oxydental Hungary Kft.</li>
                <li>
                  Székhely: 1048 Budapest, Homoktövis utca 110-112. B. Ép. 1.
                  Em. 7/C
                </li>
                <li>Adószám: 23141548-2-41</li>
                <li>Cégjegyzékszám: 01-09-954897</li>
                <li>Képviselő: dr. Vincze József Zsolt7</li>
                <li>Képviselő elérhetősége: dr.vincze@invitel.hu</li>
              </ul>
            </p>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AszfScreen;
