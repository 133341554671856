import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Container, Image } from "react-bootstrap";
import axios from "axios";
import Meta from "../../../../components/Meta/Meta";
import "./singleTreat.css";

import draftToHtml from "draftjs-to-html";

const SingleTreat = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [treatment, setTreatment] = useState({});
  const [arlista, setArlista] = useState([]);
  const [loading, setLoading] = useState(true);

  const PF = "/media/images/";

  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get("/api/kezelesek/" + path);
      setTreatment(res.data);
      setLoading(false);
   
    };
    const fetchArlista = async () => {
      const res = await axios.get("/api/arak");
      setArlista(res.data);
    };
    getPost();
    fetchArlista();
  }, [path]);

  return (
    <>
      <Meta title={treatment.title + " | Oxydental"} />
      <div id="about" className="block aboutBlock">
        <Container>
          <div className="titleHolder">
            <h2>{treatment.title}</h2>
          </div>
          <div className="contentHolder">
            <p>{treatment.shortDesc}</p>
            <Image
              className="center"
              width="100%"
              src={PF + treatment.image}
              alt={treatment.title}
              style={{marginBottom:"15px"}}
            />
              {loading ? (
              <p>...</p>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(JSON.parse(treatment.desc)),
                }}
              ></div>
            )}
            <p><strong>
              Várjuk az OXYDENTAL® Újpestben a IV. kerületben Káposztásmegyeren
              a Homoktövis lakóparkban! Rendelőnk könnyen megközelíthető a XV.
              kerületből, Dunakesziről, Fótról is illetve az M0-áson más
              régiókból is.
              </strong></p>
          </div>
        </Container>
        <Container style={{ maxwidth: "600px" }}>
          <div className="titleHolder">
            <h2>{treatment.title} árak</h2>
          </div>
          <table>
            <tbody>
              {arlista.map((item) => {
                if (
                  item.priceCategoryID === treatment.priceCategory &&
                  item.miniList === true
                ) {
                  return (
                    <tr key={item._id}>
                      <td>{item.name}</td>
                      <td className="arakCol">
                        {" "}
                        {item.priceMax
                          ? // eslint-disable-next-line
                            item.priceMin +
                            " Ft" +
                            " - " +
                            item.priceMax +
                            " Ft"
                          : item.priceMin + " Ft"}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </Container>
      </div>
    </>
  );
};

export default SingleTreat;
